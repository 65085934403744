import React, {useState, useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const ItemPropertiesField = ({ record }) => {
    const classes = useStyles();

    const [itemProps, setItemProps] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(()=> {

        dataProvider.getList('applicationarea',
            {         
              sort: { field: "id", order: "ASC" },
              pagination: { page: 1, perPage: 100 },
            },
          ).then(({ data }) => {
            setItemProps(data.map((item) => ({ id: item.id, name: item.name })));
        })
        .catch(error => {
            
        })   
      
    },[])

    return record ? (
        <span className={classes.main}>
            {record.itemProperties &&
                record.itemProperties.map(itemPropId => {

                    let itemProp;

                    if(itemProps instanceof Array){
                        itemProp = itemProps.find(s => s.id === itemPropId);
                    }                

                    return itemProp ? (
                        <Chip
                            size="small"
                            key={itemProp.id}
                            className={classes.chip}
                            label={itemProp.name}
                        />
                    ) : null;
                })}
        </span>
    ) : null;
};

ItemPropertiesField.defaultProps = {
    addLabel: true,
    source: 'itemProperties',
};

export default ItemPropertiesField;
