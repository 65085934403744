import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
    NumberField,
    ReferenceInput,
    SelectInput,
    Filter,
    required,
  } from "react-admin";

class SecondarySelectionReport extends React.PureComponent {

    // const [finishingschd, setFinishingschd] = useState({});

    // const { data: finishingschedhdr } = useQuery({
    //     type: 'getOne',
    //     resource: 'yachttemplate/finishingschedule/hdr',
    //     payload: {id:"5094"},
    //   });

    //   useEffect(() => {
    //     if (finishingschedhdr) {
    //         setFinishingschd(finishingschedhdr);
    //         console.log(`finishingschedhdr:${finishingschd}`)
    //     }
           
    //   }, [finishingschedhdr]);

    render() {
 
     const SecondarySelectionFilter = (props) => {
    
        return(
        <Filter {...props}>
            <ReferenceInput 
                alwaysOn
                label="Project Code" 
                source="yct_hdr_id" 
                reference="yachttemplate/finishingschedule/hdr"      
                perPage={1000}               
                sort={{ field: 'proj_code', order: 'ASC' }}
                //validate={[required()]}  
                >
                <SelectInput optionText="proj_code"/>
            </ReferenceInput> 
    
        </Filter>)
        }; 
    

    return (
        <List 
            basePath="/yachttemplate/finishingschedule/secondary"
            resource="yachttemplate/finishingschedule/secondary"
            filters={<SecondarySelectionFilter />} 
            sort={{ field: 'item_property', order: 'ASC'},{ field: 'finishes_code', order: 'ASC'}}
            perPage={1000}
            exporter={false}
            >
          <Datagrid>
              <TextField label="Primary Selection" source="yctTmplDet.yctTmplHdr.yctTmplRange.name"/>
              <TextField label="Type" source="applicationArea.name"/>
              <TextField label="Model" source="yctTmplDet.yctTmplHdr.model.name"/>
              <TextField label="Area" source="area.name"/> 
              <TextField label="Finishes" source="finish.name"/> 
              <TextField label="Location" source="yctTmplLocation.name"/> 
              <TextField label="UOM" source="uom"/> 
              <NumberField  label="Quantity" source="quantity"/> 
              <NumberField  label="Price" source="price"/>
              <NumberField  label="Total" source="total"/>
              <TextField label="Comments" source="comment"/>        
          </Datagrid>  
    </List> 
    );
  } 
}

export default SecondarySelectionReport;