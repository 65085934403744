import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Breadcrumb,
    BreadcrumbItem
} from '@react-admin/ra-navigation';
import { linkToRecord } from 'react-admin';

const CustomBreadcrumb =  props => {
    const classes = useStyles();
    
    const editLabel = "Edit";
    const showLabel = "Display";
    const createLabel = "Add";
    return (
        <Breadcrumb className={classes.root} {...props}>          

            {/* Employee Roles start        */}
            <BreadcrumbItem
                name="employee"
                label="Employee Roles"
                to="/employee">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.FullName}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/employee',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/employee/create"
                />            
            </BreadcrumbItem>
            {/* Employee Roles end        */}
           
            {/* Modules start        */}
            <BreadcrumbItem
                name="module"
                label="Module"
                to="/module">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/module',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/module/create"
                />            
            </BreadcrumbItem>
            {/* Modules end        */}
            
             {/* SAP Timesheet starts        */}
             <BreadcrumbItem
                name="timesheet"
                label="SAP Timesheet"
                to="/timesheet">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record.Resource ? `"${record.Resource.EmployeeName2}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/timesheet',
                                record.id
                            )}/edit`
                        : ''
                    }
                /> 
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/timesheet/create"
                />      
                <BreadcrumbItem
                    name="timesheet/dowloadtimesheet"
                    label="Download Timesheet"
                    />                     
                               
            </BreadcrumbItem>

            {/* SAP Timesheet ends        */}

            {/* Area starts here       */}
            <BreadcrumbItem
                name="area"
                label="Area"
                to="/area">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/area',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/area/create"
                />            
            </BreadcrumbItem>
            {/* Area ends here        */}

            {/* Model start        */}
            <BreadcrumbItem
                name="model"
                label="Model"
                to="/model">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/model',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/model/create"
                />            
            </BreadcrumbItem>
            {/* Model end        */}


            {/* Ranges start        */}
            <BreadcrumbItem
                name="ycttemplaterange"
                label="Ranges"
                to="/ycttemplaterange">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/ycttemplaterange',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/ycttemplaterange/create"
                />            
            </BreadcrumbItem>
            {/* Ranges end        */}

            {/* Types start        */}
            <BreadcrumbItem
                name="applicationarea"
                label="Types"
                to="/applicationarea">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/applicationarea',
                                record.id
                            )}/edit`
                        : ''
                    }
                />                     
            </BreadcrumbItem>
            {/* Types end        */}

            {/* Locations start        */}
            <BreadcrumbItem
                name="ycttemplatelocation"
                label="Locations"
                to="/ycttemplatelocation">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/ycttemplatelocation',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/ycttemplatelocation/create"
                />            
            </BreadcrumbItem>
            {/* Locations end        */}

            {/* Finishes start        */}
            <BreadcrumbItem
                name="item"
                label="Finishes"
                to="/item">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/item',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/item/create"
                />            
            </BreadcrumbItem>
            {/* Finishes end        */}


             {/* Finishing Schedule starts        */}
            <BreadcrumbItem
                name="yachttemplate/hdr"
                label="Finishing Schedule"
                to="/yachttemplate/hdr">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record ? `"${record.proj_code}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/yachttemplate/hdr',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="show"
                    label={({ record }) =>
                    `${showLabel} ${
                        record ? `"${record.proj_code}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                        record &&
                        `${linkToRecord('/yachttemplate/hdr', record.id)}/show`
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/yachttemplate/hdr/create"
                />
               
            </BreadcrumbItem>
       
             {/* Finishing Schedule ends        */}

            {/* Primary selection starts        */}

            <BreadcrumbItem
                    name="yachttemplate/primary"
                    label="Primary Selection"
                    to="/yachttemplate/primary">               
                    <BreadcrumbItem
                        name="edit"                   
                        label={({ record }) =>
                        `${editLabel} ${
                            record.yctTmplHdr ? `"${record.yctTmplHdr.proj_code}"` : '...'
                            }`
                        }
                        to={({ record }) =>
                        record
                            ? `${linkToRecord('/yachttemplate/primary',record.id)}/edit`                         
                            : ''
                        }
                    />
                    <BreadcrumbItem
                        name="show"
                        label={({ record }) =>
                        `${showLabel} ${
                            record.yctTmplHdr ? `"${record.yctTmplHdr.proj_code}"` : '...'
                            }`
                        }
                        to={({ record }) =>
                            record &&
                            `${linkToRecord('/yachttemplate/primary', record.id)}/show`
                        }
                    />
                    <BreadcrumbItem
                        name="create"
                        label={createLabel}
                        to="/yachttemplate/primary/create"
                    />
                </BreadcrumbItem>
            
            {/* Primary selection ends */}


             {/* Secondary selection starts        */}
            <BreadcrumbItem
                    name="yachttemplate/secondary"
                    label="Secondary Selection"
                    to="/yachttemplate/secondary">               
                    <BreadcrumbItem
                        name="edit"
                        label={({ record }) =>
                        `${editLabel} ${
                            record.applicationArea ? `"${record.applicationArea.name}"` : '...'
                            }`
                        }
                        to={({ record }) =>
                        record
                            ? `${linkToRecord(
                                    '/yachttemplate/secondary',
                                    record.id
                                )}/edit`
                            : ''
                        }
                    />
                    <BreadcrumbItem
                        name="show"
                        label={({ record }) =>
                        `${showLabel} ${
                            record.applicationArea ? `"${record.applicationArea.name}"` : '...'
                            }`
                        }
                        to={({ record }) =>
                            record &&
                            `${linkToRecord('/yachttemplate/secondary', record.id)}/show`
                        }
                    />
                    <BreadcrumbItem
                        name="create"
                        label={createLabel}
                        to="/yachttemplate/secondary/create"
                    />
                </BreadcrumbItem>
             {/* Secondary selection ends            */}

            {/* Moodboard starts             */}
            <BreadcrumbItem
                name="yachttemplate/moodboard"
                label="Moodboard Header"
                to="/yachttemplate/moodboard">               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record.yctTmplRange ? `"${record.yctTmplRange.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord('/yachttemplate/moodboard',record.id)}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="show"
                    label={({ record }) =>
                    `${showLabel} ${
                        record.yctTmplRange ? `"${record.yctTmplRange.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                        record &&
                        `${linkToRecord('/yachttemplate/moodboard', record.id)}/show`
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/yachttemplate/moodboard/create"
                />
            </BreadcrumbItem>

            <BreadcrumbItem
                name="yachttemplate/moodboarddet"
                label="Moodboard Details"
                to="/yachttemplate/moodboarddet"
                >               
                <BreadcrumbItem
                    name="edit"
                    label={({ record }) =>
                    `${editLabel} ${
                        record.yctTmplMoodboardHdr ? `"${record.yctTmplMoodboardHdr.yctTmplRange.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                    record
                        ? `${linkToRecord(
                                '/yachttemplate/moodboarddet',
                                record.id
                            )}/edit`
                        : ''
                    }
                />
                <BreadcrumbItem
                    name="show"
                    label={({ record }) =>
                    `${showLabel} ${
                        record.yctTmplMoodboardHdr ? `"${record.yctTmplMoodboardHdr.yctTmplRange.name}"` : '...'
                        }`
                    }
                    to={({ record }) =>
                        record &&
                        `${linkToRecord('/yachttemplate/moodboarddet', record.id)}/show`
                    }
                />
                <BreadcrumbItem
                    name="create"
                    label={createLabel}
                    to="/yachttemplate/moodboarddet/create"
                />
            </BreadcrumbItem>
           
            {/* Moodboard ends         */}

         </Breadcrumb>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        fontSize: 'small',
        // Display the Breadcrumb over the custom Layout of some pages by adding a zIndex and a maxWidth
        // @see "src/products/ProductList.tsx" or "src/visitors/VisitorList.tsx"
        maxWidth: '700px',
        zIndex: 1,
        '& a': {
            pointerEvents: 'visible',
        },
    },
}));

export default CustomBreadcrumb;