import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
    EditButton,
    DeleteButton,
  } from "react-admin";

export const RangesList = props => (
    <List  title="Yacht Template Ranges List" {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Code" source="id" />
            <TextField label="Description" source="name" />       
            <EditButton basePath='/ycttemplaterange'/>
            <DeleteButton basePath='/ycttemplaterange'/>            
        </Datagrid>
    </List>
);

export default RangesList;