import React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceInput,
    ReferenceManyField,
    TextInput,
    SelectInput,
    TextField,
    FormDataConsumer,
    required,
  } from "react-admin";
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

const authRoles = [
    { id: 'Full', name: 'Full' },
    { id: 'Read Only', name: 'Read Only' },
    { id: 'None', name: 'None' },
];

const RolesForm = props => (
    <RowForm {...props}>
        <ReferenceInput 
            label="Menu"
            source="module_id" 
            reference="module"                     
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}  
            validate={required()}
            {...props}>
            <SelectInput />
        </ReferenceInput> 

        <FormDataConsumer>
          {({ formData, ...rest }) => 
            //formData.module_id &&
              <ReferenceInput 	
                label="Menu Item" 
                source="module_det_id"
                reference="module/detail"
                sort={{ field: 'subject', order: 'ASC' }}
                filter={{ module_id: formData.module_id, dependency: true}}
                perPage={1000}>            
                <SelectInput optionText="subject" {...rest}/>
             </ReferenceInput>
          }
        </FormDataConsumer>

        <SelectInput
            source="auth"
            label="Authorization"
            validate={required()}
            choices={authRoles}
        />
    </RowForm>
);

const RolesEdit = ({ id, ...props })=> {

    const requiredValidate = [required()];

return (
    <Edit
      title="Add/Edit Roles"  
      id={id}
      {...props} 
    >
      <SimpleForm      
      >            
        <TextInput disabled label="Full Name" source="FullName" />
        <ReferenceManyField
            fullWidth
            label="Roles Details"
            reference="employee/role"
            target="user_id"
        >
            <EditableDatagrid
                undoable
                createForm={
                    <RolesForm initialValues={{ user_id: id }} />
                }
                editForm={<RolesForm />}
                rowClick="edit"
            >
                <TextField source="module.name" label="Menu" />
                <TextField source="moduleDet.subject" label="Menu Item" />
                <TextField source="auth" label="Authorization" />
            </EditableDatagrid>
        </ReferenceManyField>
      </SimpleForm>  
</Edit> 
    )   
}

export default RolesEdit;