import React from 'react';
import {
  Datagrid,
  ShowButton,
  DeleteButton,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  NumberField,
  Pagination,
  ImageField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RefreshPrimarySelectionButton from './RefreshPrimarySelectionButton';

const useStyles = makeStyles({
  image: {
    
    height:'100px',
    border: '2px',
  },
});

const YachtTemplateShow = props => 
{
  const classes = useStyles();

  return(<Show  title="Display Yacht Template" {...props}>
    <TabbedShowLayout>
      <Tab label="Yacht Template Summary">
          <TextField label="Project Code" source="proj_code"/>
          <TextField label="SAP Project Code" source="sap_proj_code" />
          <TextField label="Project Manager" source="proj_mgr_id" />
          <TextField label="Model" source="model.name" />
          <TextField label="Ranges" source="yctTmplRange.name" />
          <TextField label="Vessel Name" source="vessel_name" />
          <DateField label="Launch Date" source="launch_date" />
          <TextField label="Client" source="client_code" />
      </Tab>

      <Tab label="Primary Selection Details" path="yachttemplate/primary">
        <ReferenceManyField
          addLabel={false}
          reference="yachttemplate/primary"
          target="yct_hdr_id"
          sort={{ field: 'id', order: 'DESC' }}
          pagination={<Pagination />}
          perPage={250}
        >        
            <Datagrid rowClick="edit">
              <TextField label="Project Code" source="yctTmplHdr.proj_code"/>
              <TextField label="Yacht Template" source="yctTmplHdr.yctTmplRange.name"/>
              <TextField label="Type" source="applicationArea.name"/>
              <TextField label="Model" source="yctTmplHdr.model.name"/>
              <TextField label="Finishes" source="finish.name"/> 
              <ImageField source="finish.thumbnail_url" label="Thumbnail" title="image_title" />
              <ShowButton />
              <DeleteButton/>
            </Datagrid>
         
        </ReferenceManyField>
        <RefreshPrimarySelectionButton/>   
      </Tab>

      
      <Tab label="Secondary Selection Details" path="yachttemplate/secondary">
        <ReferenceManyField
          addLabel={false}
          reference="yachttemplate/secondary"
          target="yctTmplDet.yct_hdr_id"
          sort={{ field: 'id', order: 'DESC' }}
          pagination={<Pagination />}
          perPage={250}
        >
            <Datagrid rowClick="edit">
              <TextField label="Primary Selection" source="yctTmplDet.yctTmplHdr.yctTmplRange.name"/>
              <TextField label="Type" source="yctTmplDet.applicationArea.name"/>
              <TextField label="Model" source="yctTmplDet.yctTmplHdr.model.name"/>
              <TextField label="Area" source="area.name"/> 
              <TextField label="Finishes" source="finish.name"/> 
              <TextField label="Location" source="yctTmplLocation.name"/> 
              <TextField label="UOM" source="uom"/> 
              <NumberField  label="Quantity" source="quantity"/> 
              <NumberField  label="Price" source="price"/>
              <NumberField  label="Total" source="total"/>
              <TextField label="Comments" source="comment"/>        
              <ImageField source="finish.thumbnail_url" label="Thumbnail" title="image_title" />  
            </Datagrid>
        </ReferenceManyField>   
       
      </Tab>
    </TabbedShowLayout>
  </Show>)
};

export default YachtTemplateShow;
