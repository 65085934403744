import React from 'react';
import {
  Datagrid,
  ShowButton,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  ImageField,
  Pagination,
} from 'react-admin';

const YachtTemplateDet2Show = props => (
  <Show  title="Display Secondary Selection" {...props}>
  <TabbedShowLayout>
    <Tab label="Summary">
        
        <TextField label="Ranges" source="yctTmplDet.yctTmplHdr.yctTmplRange.name"/>
        <TextField label="Type" source="applicationArea.name"/>
        <TextField label="Finishes" source="finish.name"/>     
        <TextField label="Comments" source="comment"/>  
    </Tab>
    <Tab label="Details" path="yachttemplate/secondary">
      <ReferenceManyField
        addLabel={false}
        reference="yachttemplate/secondary"
        target="yct_det_id"
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination />}
        perPage={250}
      >
          <Datagrid rowClick="edit">
            <TextField label="Id" source="id" />
            <TextField label="Primary Selection" source="yctTmplDet.id"/>
            <TextField label="Type" source="applicationArea.name"/>
            <TextField label="Finishes" source="finish.name"/> 
            <ImageField source="finish.thumbnail_url" label="Thumbnail" title="image_title" /> 
            <ShowButton />
          </Datagrid>
      </ReferenceManyField>
      {/* <AddPrimarySelectionButton /> */}
    </Tab>
  </TabbedShowLayout>
</Show>
);

export default YachtTemplateDet2Show;
