import React, {
    useState,
    useEffect,
    useCallback,
    CSSProperties,
    ReactElement,
} from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import Welcome from './Welcome';
import GetCurrentWeekDay from '../helpers/routines'

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { marginLeft: '0.5em', maxWidth: '30em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
    const [state, setState] = useState({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    );

    // const fetchHoursClocked = useCallback(async () => {
    //     const currWeekCode = GetCurrentWeekDay();
       
    //     const { data: weekTimesheets } = await dataProvider.getList(
    //         'timesheet',
    //         {
    //             filter: { WeekCode: currWeekCode },
    //             sort: { field: 'weekcode', order: 'DESC' },
    //             pagination: { page: 1, perPage: 50 },
    //         }
    //     );

    //     const stats = {
    //         sumHoursWorked:0,
    //         employee:'',
    //         timesheets:[],
    //         employees:[]
    //     };

    //     const aggregations = weekTimesheets
    //         .filter(timesheet => timesheet.HoursWorked !== 0)
    //         .reduce(
    //             (stats, timesheet) => {
    //                 stats.sumHoursWorked += timesheet.HoursWorked;
                    
                   
    //                 stats.timesheets.push(timesheet);

    //                 stats.employees.push(timesheet);

    //                 return stats;
    //             },
    //             {
    //                 revenue: 0,
    //                 nbNewOrders: 0,
    //                 pendingOrders: [],
    //             }
    //         );

    //     setState(state => ({
    //         ...state,
    //         recentOrders,
    //         revenue: aggregations.revenue.toLocaleString(undefined, {
    //             style: 'currency',
    //             currency: 'USD',
    //             minimumFractionDigits: 0,
    //             maximumFractionDigits: 0,
    //         }),
    //         nbNewOrders: aggregations.nbNewOrders,
    //         pendingOrders: aggregations.pendingOrders,
    //     }));
    //     const { data: customers } = await dataProvider.getMany<Customer>(
    //         'customers',
    //         {
    //             ids: aggregations.pendingOrders.map(
    //                 (order) => order.customer_id
    //             ),
    //         }
    //     );
    //     setState(state => ({
    //         ...state,
    //         pendingOrdersCustomers: customers.reduce(
    //             (prev, customer) => {
    //                 prev[customer.id] = customer; // eslint-disable-line no-param-reassign
    //                 return prev;
    //             },
    //             {}
    //         ),
    //     }));
    // }, [dataProvider]);

    // useEffect(() => {
    //     fetchHoursClocked();
    // }, [version]); 

    const {
        nbClockedHours,
        nbClockedEmployees,
        WeeklyHoursClocked,
        EmployeeList,
    } = state;
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn}>
                <Welcome />
                <VerticalSpacer />              
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn}>
            <div style={styles.singleCol}>
                <Welcome />
            </div>
            <div style={styles.flex}>
               
            </div>
            <div style={styles.singleCol}>
                
            </div>
            <div style={styles.singleCol}>
               
            </div>
            <div style={styles.singleCol}>
               
            </div>
        </div>
    ) : (
        <>
            <Welcome />
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                       
                    </div>
                    <div style={styles.singleCol}>
                       
                    </div>
                    <div style={styles.flex}>
                        
                    </div>
                </div>
                <div style={styles.rightCol}>
                   
                </div>
            </div>
        </>
    );
};

export default Dashboard;
