import React from 'react';
import {
  Datagrid,
  ShowButton,
  DeleteButton,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  Pagination
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import AddMoodBoardDetailsButton from './AddMoodBoardDetailsButton';
import SelectedMoodboardFinishesField from '../moodboarddetails/SelectedMoodboardFinishesField';

const useStyles = makeStyles(theme => ({
  nb_commands: { color: 'purple' },
  hiddenOnSmallScreens: {
      display: 'table-cell',
      [theme.breakpoints.down('md')]: {
          display: 'none',
      },
  },
}));

const MoodboardShow = props => {
  const classes = useStyles();

return(
  <Show  title="Display Mood Board" {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField label="Ranges" source="yctTmplRange.name" />
        <TextField label="Description" source="description" /> 
      </Tab>
      <Tab label="Mood Board Details" path="yachttemplate/moodboarddet">
        <ReferenceManyField
          addLabel={false}
          reference="yachttemplate/moodboarddet"
          target="moodboard_hdr_id"
          sort={{ field: 'id', order: 'DESC' }}
          pagination={<Pagination />}
          perPage={250}
        >
          <Datagrid rowClick="edit">
            <TextField label="Range" source="yctTmplMoodboardHdr.yctTmplRange.name" />
            <TextField label="Type" source="applicationArea.name" />
            <SelectedMoodboardFinishesField
              cellClassName={classes.hiddenOnSmallScreens}
              headerClassName={classes.hiddenOnSmallScreens}
            />
            <ShowButton />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
        <AddMoodBoardDetailsButton />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
} 

export default MoodboardShow;
