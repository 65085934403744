import React from 'react';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddMoodBoardDetailsButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={`/yachttemplate/moodboarddet/create?moodboard_hdr_id=${record.id}`}
    label="Add Mood Board Item"
    title="Add a line item for MoodBoard"
  >
    <ChatBubbleIcon />
  </Button>
);

export default withStyles(styles)(AddMoodBoardDetailsButton);
