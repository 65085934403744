import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import { 
    Title, 
    PasswordInput,
    useDataProvider, 
    useNotify, 
    useRedirect,
    Button,
    required, 
        
  } from 'react-admin';
import { Form } from 'react-final-form'

const ChangePassword = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const [loading, setLoading] = useState(false);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const onSubmit = async (values) => {
         
        setLoading(true);
        await sleep(3000);

        //get
        const auth = JSON.parse(sessionStorage.getItem('auth'));

        const dataToPost = {...values, 
            id: auth.id, 
            UserName: auth.UserName,
            Password: values.newPassword 
        };

        //console.log(`dataToPost: ${dataToPost}`)

        dataProvider
        .update('authentication/ConfirmPassword', {id: dataToPost.id, data: dataToPost})
        .then(response => {            
            redirect('/login');
            notify('Password Successfully Changed');
        })
        .catch(error => {
            setLoading(false);
            notify(`Password Change not successful: ${error.message}`, 'warning');
    });
    
}


    const requiredValidate = [required()];

    const confirmPassword = (values) => {
        const errors = {};
        if (!values.newPassword) {
            errors.newPassword = ['The New Password is required'];
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = ['The Confirm Password is required'];
        } 
        if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = ['The New Password and Confirm Password must be the same'];
        } 

        return errors
    };
    

    return(
        <Card>
            <Title title="Change Password Here" />
            <CardContent>
            <Form
                onSubmit={onSubmit}
                validate={confirmPassword}
                render={({ handleSubmit, form, submitting, pristine, values })  => (
                <form onSubmit={handleSubmit}>
               
               <h1>Change Password</h1>
          
               <Grid item xs={12}>
                    {loading ? <CircularProgress/> : <span></span>}  
               </Grid>
    
              <div>      
                        <PasswordInput 
                            source="newPassword" 
                            label="New Password" 
                            validate={requiredValidate} />   
              </div>     
              
              <div>      
                        <PasswordInput source="confirmPassword" 
                            inputProps={{ autocomplete: 'current-password' }} 
                            label="Confirm Password"
                            validate={requiredValidate} 
                            />   
               </div>               
             
                <div>      
        
                <Button 
                        label="Submit" 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        disabled={submitting || pristine}>
                   
                </Button>   &nbsp;
                <Button 
                        label="Reset"  
                        variant="contained" 
                        color="primary" 
                        onClick={form.reset} 
                        disabled={submitting || pristine}>
                   
                </Button>
                </div>
        
               
                </form>
                )}
            />      
            </CardContent>
        </Card>
        )
}

export default ChangePassword