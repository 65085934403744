import React, {useState, useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const AreasField = ({ record }) => {
    const classes = useStyles();

    const [areas, setAreas] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(()=> {

        dataProvider.getList('area',
            {         
              sort: { field: "id", order: "ASC" },
              pagination: { page: 1, perPage: 100 },
            },
          ).then(({ data }) => {
            setAreas(data.map((item) => ({ id: item.id, name: item.name })));
        })
        .catch(error => {
            
        })   
      
    },[])

    return record ? (
        <span className={classes.main}>
            {record.selectedAreas &&
                record.selectedAreas.map(areaId => {

                    let area;

                    if(areas instanceof Array){
                        area = areas.find(s => s.id === areaId);
                    }                

                    return area ? (
                        <Chip
                            size="small"
                            key={area.id}
                            className={classes.chip}
                            label={area.name}
                        />
                    ) : null;
                })}
        </span>
    ) : null;
};

AreasField.defaultProps = {
    addLabel: true,
    source: 'itemProperties',
};

export default AreasField;
