import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
  } from "react-admin";

const CategoryEdit = (props)=> {

    const requiredValidate = [required()];

return (
    <Edit
      title="Edit Yacht Template Category"  
      {...props}
    >
      <SimpleForm      
      >            
        <TextInput label="Code" source="id"  validate={requiredValidate}/>
        <TextInput label="Description" source="name"  validate={requiredValidate}/>
      </SimpleForm>  
</Edit> 
    )   
}

export default CategoryEdit;