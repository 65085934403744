import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
  } from "react-admin";
import AreasField from './AreasField'  

export const ApplicationAreaList = props => (
    <List  title="Application Areas List" {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Code" source="id" />
            <TextField label="Description" source="name" />   
            <AreasField label="Areas"/>                  
        </Datagrid>
    </List>
);

export default ApplicationAreaList;