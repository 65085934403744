import React, { useState, useEffect } from "react";
import { Resource, fetchUtils } from "react-admin";
import { Admin } from "@react-admin/ra-enterprise";
import { createMemoryHistory } from "history";

import "./App.css";

import { Dashboard } from "./dashboard";
import customRoutes from "./configuration/routes";
import authProvider from "./authentication/authProvider";
import { Login, Layout } from "./layout";
import { getThemes } from "./layout/themes";

//import jsonServerProvider from "ra-data-json-server";
import data from "./dataProvider/index";

import timesheet from "./timesheet/index";
import ERSTimeSheetList from "./timesheet/ERSTimeSheetList";
import ERSSummarisedTimeSheetList from "./timesheet/ERSSummarisedTimeSheetList";
import resource from "./resource/index";
import planning from "./planning/index";
import appl_areas from "./yacht_template/application_areas/index";
import modules from "./module";
import roles from "./authorization";
import models from "./model";
import areas from "./area";

import yacht_ranges from "./yacht_template/ranges/index";
import yacht_categories from "./yacht_template/categories/index";
import yacht_locations from "./yacht_template/locations/index";
import yacht_finishes from "./yacht_template/finishes/index";
import yacht_moodboards from "./yacht_template/moodboard/index";
import yacht_moodboardsdet from "./yacht_template/moodboarddetails/index";
import yacht_template from "./yacht_template/index";
import yacht_template_pry from "./yacht_template/primary_selection/index";
import yacht_template_sec from "./yacht_template/secondary_selection/index";

import SecondarySelectionReport from "./reports/finishing_schedule/SecondarySelectionReport";
import SecondarySelectionGroupByFinishReport from "./reports/finishing_schedule/SecondarySelectionGroupByFinishReport";
import SecondarySelectionGroupByLocationReport from "./reports/finishing_schedule/SecondarySelectionGroupByLocationReport";

import config from "./config";
import themeReducer from "./themeReducer";
import { useTheme } from "@material-ui/core";

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    const httpClient = (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
      }

      const auth = JSON.parse(sessionStorage.getItem("auth"));
      options.headers.set("Authorization", `Bearer ${auth.token}`);
      return fetchUtils.fetchJson(url, options);
    };

    //setDataProvider(jsonServerProvider(config.webApi.URL, httpClient));
    setDataProvider(data(config.webApi.URL, httpClient));
  }, []);

  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  const theme = useTheme();
  const { darkTheme, lightTheme } = getThemes(theme);

  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      customRoutes={customRoutes}
      authProvider={authProvider}
      customReducers={{ theme: themeReducer }}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      // Ra-enterprise confirguration
      lightTheme={lightTheme}
      darkTheme={darkTheme}
    >
      <Resource name="timesheet" {...timesheet} />
      <Resource name="erstimesheet" list={ERSTimeSheetList} />
      <Resource
        name="erssummarizedtimesheet"
        list={ERSSummarisedTimeSheetList}
      />
      <Resource name="resource" {...resource} />
      <Resource name="planning" {...planning} />

      <Resource name="worktype" />
      <Resource name="misc/weekcode" />
      <Resource name="authentication/logins" />

      <Resource name="item" {...yacht_finishes} />
      <Resource name="item/applicableitems" />
      <Resource name="item/selectedmoodboarditems" />
      <Resource name="itemgroup" />
      <Resource name="supplier" />
      <Resource name="customer" />
      <Resource name="employee" {...roles} />
      <Resource name="employee/role" />
      <Resource name="projectmanager" />
      <Resource name="manufacturer" />
      <Resource name="area" {...areas} />
      <Resource name="applicationarea" {...appl_areas} />
      <Resource name="module" {...modules} />
      <Resource name="module/detail" />
      <Resource name="model" {...models} />
      <Resource name="areamodel" />

      <Resource name="ycttemplaterange" {...yacht_ranges} />
      <Resource name="ycttemplatecategory" {...yacht_categories} />
      <Resource name="ycttemplatelocation" {...yacht_locations} />
      <Resource name="ycttemplatefinish" {...yacht_finishes} />

      <Resource name="yachttemplate/moodboard" {...yacht_moodboards} />
      <Resource name="yachttemplate/moodboarddet" {...yacht_moodboardsdet} />

      <Resource name="yachttemplate/hdr" {...yacht_template} />
      <Resource name="yachttemplate/primary" {...yacht_template_pry} />
      <Resource name="yachttemplate/secondary" {...yacht_template_sec} />
      <Resource name="yachttemplate/primary/refresh" />
      <Resource name="yachttemplate/secondary/refresh" />

      <Resource name="yachttemplate/finishingschedule/hdr" />
      <Resource
        name="yachttemplate/finishingschedule/secondary"
        list={SecondarySelectionReport}
      />
      <Resource
        name="yachttemplate/finishingschedule/secondary/groupbyfinish"
        list={SecondarySelectionGroupByFinishReport}
      />
      <Resource
        name="yachttemplate/finishingschedule/secondary/groupbylocation"
        list={SecondarySelectionGroupByLocationReport}
      />
    </Admin>
  );
};

export default App;
