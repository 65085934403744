const dev = {
  webApi: {
    URL: "http://localhost/KnysnaEportal/ePortalAPI/api",
  },
};

const prod = {
  webApi: {
    //URL: "https://dev.knysnayachtco.com/KnysnaEportal/ePortalAPI",
    //URL: "https://webapi.knysnayachtco.com/KnysnaEportal/ePortalAPI",
    URL: "https://b1-server.knysnayachtco.com/KnysnaEPortal",
  },
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
  ...config,
};
