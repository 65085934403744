import { alpha, createTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import createPalette from "@material-ui/core/styles/createPalette";
import { buildThemeFromTypeMethod } from "@react-admin/ra-enterprise";
const borderRadius = 10;

export const getThemes = (theme) => {
  const buildTheme = buildThemeFromTypeMethod(
    theme,
    lightTheme(theme),
    darkTheme(theme)
  );
  return {
    darkTheme: buildTheme("dark"),
    lightTheme: buildTheme("light"),
  };
};

const darkPalette = createPalette({
  mode: "dark", // Switching the dark mode on is a single property value change.
  primary: {
    dark: "#495e84",
    main: "#698baf",
    light: "#7ba8c9",
    contrastText: "#fff",
  },
  secondary: {
    dark: "#7d6327",
    main: "#b48e38",
    light: "#c3a45f",
    contrastText: "#fff",
  },
  background: {
    paper: "#202020",
    default: "#161616",
  },
});

const lightPalette = createPalette({
  mode: "light",
  primary: {
    dark: "#2e3e54",
    main: "#495e84",
    light: "#698baf",
    contrastText: "#fff",
  },
  secondary: {
    dark: "#7d6327",
    main: "#b48e38",
    light: "#c3a45f",
    contrastText: "#fff",
  },
  background: {
    paper: "#ffffff",
    default: "#fafafa",
  },
});

export const darkTheme = (theme) => {
  return {
    palette: darkPalette,
    shape: {
      borderRadius,
    },

    RaLayout: {
      root: {
        "& .RaLayout-content": {
          padding: `${theme.spacing(1)}`,
          [theme.breakpoints.up("md")]: {
            padding: `${theme.spacing(2)}`,
          },
        },
      },
    },
    MuiAppBar: {
      root: {
        "& .RaAppBar-menuButton": {
          // Since sub-<Menu /> hide labels when sidebar is closed
          // We need to disallow sidebar closing on desktop (hiding button is simpler)
          display: "block",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
      },
    },
    RaAppBar: {
      root: {
        backgroundColor: darkPalette.background.paper,
        color: "#fff",
        "& .RaAppBar-menuButton": {
          // Since sub-<Menu /> hide labels when sidebar is closed
          // We need to disallow sidebar closing on desktop (hiding button is simpler)
          display: "block",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
      },
    },
    RaSearchInput: {
      root: {
        color: darkPalette.common.white,
        backgroundColor: alpha(darkPalette.common.black, 0.04),
        "&:hover": {
          backgroundColor: alpha(darkPalette.common.black, 0.13),
        },
        "&:focus": {
          backgroundColor: alpha(darkPalette.common.black, 0.13),
        },
        "&:focus-within": {
          backgroundColor: alpha(darkPalette.common.black, 0.13),
        },
        "& .RaSearchInput-inputBase": {
          background: alpha(darkPalette.common.black, 0.04),
          "&:hover": {
            background: alpha(darkPalette.common.black, 0.1),
          },
        },
        "& .RaSearchInput-inputAdornmentStart": {
          color: darkPalette.common.white,
        },
      },
    },

    RaSidebar: {
      root: {
        drawerPaper: {
          paddingRight: 100,
          width: "auto",
        },
        "& .RaSidebar-fixed": {
          zIndex: 1200,
          width: 100,
        },
        "&.MuiDrawer-docked .MuiPaper-root": {
          width: 100,
        },
      },
    },
    RaMultiLevelMenu: {
      root: {
        "& .RaMultiLevelMenu-navWithCategories": {
          backgroundColor: darkPalette.background.paper,
        },
      },
    },
    RaMenuItemCategory: {
      root: {
        "& .RaMenuItemCategory-container": {
          color: "white",
        },
        "& .RaMenuItemCategory-active": {
          color: "cardBackground",
          backgroundColor: grey[800],
        },
        "& .RaMenuItemCategory-popoverPaper": {
          backgroundColor: darkPalette.background.paper,
        },
      },
    },
    RaTopToolbar: {
      root: {
        paddingTop: 0,
        width: "auto",
        minHeight: "auto",
        alignItems: "flex-end",
      },
    },
    RaListToolbar: {
      root: {
        alignItems: "center",
        paddingTop: 0,
        "& .RaListToolbar-actions": {
          alignItems: "center",
          paddingTop: 0,
          minHeight: "auto",
        },
        "& .RaListToolbar-toolbar": {
          minHeight: "auto",
        },
      },
    },
    MuiPaper: {
      root: {
        border: "none",
        background: darkPalette.background.paper,
      },
    },
  };
};

export const lightTheme = (theme) => ({
  palette: lightPalette,
  shape: {
    borderRadius,
  },
  overrides: {
    RaLayout: {
      root: {
        "& .RaLayout-content": {
          padding: `${theme.spacing(1)}`,
          [theme.breakpoints.up("md")]: {
            padding: `${theme.spacing(2)}`,
          },
        },
      },
    },
    RaAppBar: {
      root: {
        "& .RaAppBar-menuButton": {
          // Since sub-<Menu /> hide labels when sidebar is closed
          // We need to disallow sidebar closing on desktop (hiding button is simpler)
          display: "block",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
      },
    },
    RaSearchInput: {
      root: {
        color: lightPalette.text.primary,
        backgroundColor: alpha(lightPalette.common.black, 0.04),
        "&:hover": {
          backgroundColor: alpha(lightPalette.common.black, 0.13),
        },
        "&:focus": {
          backgroundColor: alpha(lightPalette.common.black, 0.13),
        },
        "&:focus-within": {
          backgroundColor: alpha(lightPalette.common.black, 0.13),
        },
        "& .RaSearchInput-inputBase": {
          borderRadius,
          background: alpha(lightPalette.common.black, 0.04),
          "&:hover": {
            background: alpha(lightPalette.common.black, 0.1),
          },
        },
        "& .RaSearchInput-inputAdornmentStart": {
          color: alpha("#000000", 0.38),
        },
      },
    },
    RaMenuItemLink: {
      root: {
        borderLeft: "3px solid #fff",
        "& .RaMenuItemLink-active": {
          borderLeft: "3px solid #808080",
        },
      },
    },
    RaMenuItemCategory: {
      root: {
        "& .RaMenuItemCategory-container": {
          color: "#808080",
          "&:hover": {
            color: "black",
            backgroundColor: grey[200],
          },
        },
        "& .RaMenuItemCategory-popoverPaper": {
          boxShadow: theme.shadows[2],
          backgroundColor: lightPalette.background.paper,
        },
      },
    },
    RaMenuItem: {
      root: {
        color: "#808080",
      },
    },
    RaMultiLevelMenu: {
      root: {
        "& .RaMultiLevelMenu-navWithCategories": {
          backgroundColor: "#fff",
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiAppBar: {
      // Hide MenuItemCategory shadow behind the appbar
      root: { zIndex: 9999 },
      colorSecondary: {
        color: "#808080",
        backgroundColor: "#fff",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    RaSidebar: {
      drawerPaper: {
        paddingRight: 100,
        width: "auto",
      },
      fixed: {
        zIndex: 1200,
        width: 100,
      },
      "&.MuiDrawer-docked .MuiPaper-root": {
        width: 100,
      },
    },
    RaFilterFormInput: {
      body: {
        // Fixes search filter breadcrumb overlap
        "& > div": {
          marginTop: 8,
        },
      },
    },
    RaLinkedData: {
      root: {
        "&:hover": {
          backgroundColor: "#ddd",
        },
      },
    },
    RaTopToolbar: {
      root: {
        paddingTop: 0,
        width: "auto",
        minHeight: "auto",
        alignItems: "flex-end",
      },
    },
    RaListToolbar: {
      root: {
        alignItems: "center",
        paddingTop: 0,
        "& .RaListToolbar-actions": {
          alignItems: "center",
          minHeight: "auto",
          paddingTop: 0,
        },
        "& .RaListToolbar-toolbar": {
          minHeight: "auto",
        },
      },
    },
  },
});
