import * as React from 'react';
import { Box, Card, CardActions, Button, Typography } from '@material-ui/core';

import AVTimerIcon from '@material-ui/icons/AvTimer';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import  LanguageIcon from '@material-ui/icons/Language';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

import publishArticleImage from './welcome_illustration.svg';

const useStyles = makeStyles(theme => ({
    root: {
        background:
            theme.palette.type === 'dark'
                ? '#535353'
                : `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,
        color: '#fff',
        padding: 20,
        marginTop: theme.spacing(2),
        marginBottom: '1em',
    },
    media: {
        background: `url(${publishArticleImage}) top right / cover`,
        marginLeft: 'auto',
    },
    actions: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
            flexWrap: 'wrap',
            '& a': {
                marginTop: '1em',
                marginLeft: '0!important',
                marginRight: '1em',
            },
        },
    },
}));

const Welcome = () => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        Welcome to Knysna E-Portal
                    </Typography>
                    <Box maxWidth="50em">
                        <Typography variant="body1" component="p" gutterBottom>
                            This is the platform to admin timesheet, configure Yacht Template and the rest
                        </Typography>
                    </Box>
                    <CardActions className={classes.actions}>
                        <Button
                            variant="contained"
                            href="#/timesheet"
                            startIcon={<AVTimerIcon />}
                        >
                            Timesheets
                        </Button>

                        <Button
                            variant="contained"
                            href="#/yachttemplate/hdr"
                            startIcon={<DirectionsBoatIcon />}
                        >
                            Yacht Template
                        </Button>
                       
                        <Button
                            variant="contained"
                            href="https://knysnayachtco.com/"
                            target="demo_source_code"
                            startIcon={<LanguageIcon />}
                        >
                            Website
                        </Button>
                    </CardActions>
                </Box>

                <Box
                    display={{ xs: 'none', sm: 'none', md: 'block' }}
                    className={classes.media}
                    width="16em"
                    height="9em"
                    overflow="hidden"
                />
            </Box>
        </Card>
    );
};

export default Welcome;
