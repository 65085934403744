import React, {useState, useEffect } from 'react';
import {
  Edit,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  SimpleForm,
  useDataProvider,
  FormWithRedirect
} from 'react-admin';
import { useDefineAppLocation } from '@react-admin/ra-navigation';
import { Card, CardContent, CircularProgress} from '@material-ui/core';
import { useFormState } from 'react-final-form'
import FinishPreview from '../finishes/FinishPreview'
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import { useLocation } from 'react-router';
import {Fetch_Get} from '../../helpers/getQueries'

const styles = {
  price: { width: '7em' },
  width: { width: '7em' },
  height: { width: '7em' },
  stock: { width: '7em' },
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};


const useStyles = makeStyles({
  ...styles,
  comment: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 256,
  },
  container: {
      display: 'flex',
      '& > :first-child': {
          flex: 1,
          minWidth: '60%',
          maxWidth: '70%',
      },
      '& > :last-child': {
          width: '25%',
          flexShrink: 0,
      },
  },
  root: {
      padding: '0 !important',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& .tabbed-form': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
      },
      '& .toolbar': {
          marginTop: 'auto',
      },
  },
});

// const FinishEditFormWithPreview = ({
//   children,
//   ...props
// }) => {
//   const classes = useStyles();
//   useDefineAppLocation('yachttemplate/primary.edit', props);
//   return (
//       <FormWithRedirect
//           {...props}
//           render={({ component, ...formProps }) => {
//               return (
//                   <div className={classes.container}>
//                       <Card>
//                           <CardContent className={classes.root}>
//                               <SimpleForm {...formProps}>
//                                   {children}
//                               </SimpleForm>
//                           </CardContent>
//                       </Card>
//                       <div data-testid="product-edit-preview">
//                           <FormDataConsumer>
//                               {({ formData }) => {
//                                   return <FinishPreview record={formData} />;
//                               }}
//                           </FormDataConsumer>
//                       </div>
//                   </div>
//               );
//           }}
//       />
//   );
// };


const YachtTemplateDetEdit = props => {

  const dataProvider = useDataProvider();
  const location = useLocation();
  const [redirect, setRedirect] = useState();
  //useDefineAppLocation('yachttemplate/hdr.yachttemplate/primary.edit', props);
  
  useEffect(()=> {
    const lastBackLash = location.pathname.lastIndexOf("/");
    const pry_selection_id = location.pathname.substr(lastBackLash+1);

    dataProvider.getOne('YachtTemplate/Primary',{ id: pry_selection_id})
      .then(({ data }) => {
        setRedirect(`/yachttemplate/hdr/${data.yct_hdr_id}/show/yachttemplate/primary`);
      })
    .catch(error => { })   
  },[redirect])

  
  const PhotoField = () => {

    const { values: { finishes_code,thumbnail_url }} = useFormState({ subscription: { values: true } });  
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    const [finishObj, setFinishObj] = useState({});
    const [loading, setLoading] = useState(false);

    const style = {
      image : {
        width: '200pt',
        height: '200pt'
      }
    }

    useEffect(async() => {
         
      if(finishes_code !== '' && typeof finishes_code !== 'undefined'){
        
        setLoading(true);
        //await sleep(1000);

        await Fetch_Get().getFinishesById(finishes_code)
        .then((finish)=> {
          setFinishObj(finish)   
          setLoading(false);
        });

      }
      //console.log(`finishObj:${typeof finishObj}`);
    }, [finishes_code]);

    return( 
      
      <div>

      {loading ? <div><CircularProgress loadingPrimary="Downloading..."  /></div> : <span></span>} 
      

      {typeof finishObj !== 'undefined' && Object.keys(finishObj).length !== 0 && finishObj.thumbnail_url !== '' ? 
      <img src={`${finishObj.thumbnail_url}`} style={style.image} alt="" />:
      <img src={`${thumbnail_url}`} style={style.image} alt="" />  }
    
      </div>

    )  

   }


  return (
    <Edit  title="Edit Primary Selection" {...props}>
      <SimpleForm
        redirect={redirect}
        >
        <TextInput disabled label="Ranges" source="yctTmplHdr.yctTmplRange.name"/>
        <TextInput disabled label="Type" source="applicationArea.name"/>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return(
              <ReferenceInput 
                allowEmpty={true}
                label="Finishes" 
                source="finishes_code"
                reference="item/selectedmoodboarditems"
                sort={{ field: 'finishes_code', order: 'ASC' }}
                filter={{ item_property: formData.item_property,range_code: formData.yctTmplHdr.yctTmplRange.id}}
                perPage={1000}                                        
                >            
              <AutocompleteInput />
            </ReferenceInput>
            )
          }}
        </FormDataConsumer>

        <PhotoField/>
      
        <RichTextInput  label="Comments" source="comment" 
            toolbar={[ ['bold', 'italic', 'underline', 'link'] ]} />

      </SimpleForm>

    </Edit>
  );
};

export default YachtTemplateDetEdit;
