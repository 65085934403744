import React from 'react';
import {
  Datagrid,
  ShowButton,
  DeleteButton,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  ImageField,
  Pagination
} from 'react-admin';
import RefreshSecondarySelectionButton from './RefreshSecondarySelectionButton'

const YachtTemplateDetShow = props => (
  <Show  title="Display Primary Selection" {...props}>
  <TabbedShowLayout>
    <Tab label="Primary Selection Summary">
        
        <TextField label="Ranges" source="yctTmplHdr.yctTmplRange.name"/>
        <TextField label="Type" source="applicationArea.name"/>
        <TextField label="Model" source="yctTmplHdr.model.name"/>
        <TextField label="Finishes" source="finish.name"/>     
        <TextField label="Comments" source="comment"/>  
    </Tab>
    <Tab label="Secondary Selection Details" path="yachttemplate/secondary">
      <ReferenceManyField
        addLabel={false}
        reference="yachttemplate/secondary"
        target="yct_det_id"
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination />}
        perPage={250}
      >
          <Datagrid rowClick="edit">
            <TextField label="Primary Selection" source="yctTmplDet.yctTmplHdr.yctTmplRange.name"/>
            <TextField label="Type" source="yctTmplDet.applicationArea.name"/>
            <TextField label="Model" source="yctTmplDet.yctTmplHdr.model.name"/> 
            <TextField label="Area" source="area.name"/> 
            <TextField label="Finishes" source="finish.name"/> 
            <TextField label="Location" source="yctTmplLocation.name"/> 
            <TextField label="UOM" source="uom"/> 
            <NumberField  label="Quantity" source="quantity"/> 
            <NumberField  label="Price" source="price"/>
            <NumberField  label="Total" source="total"/>
            <TextField label="Comments" source="comment"/>
            <ImageField source="finish.thumbnail_url" label="Thumbnail" title="image_title" /> 
            <DeleteButton/>
            {/* <ImageField  label="Thumbnail" source="finish.thumbnail_url"/> */}
            {/* <ShowButton /> */}
        </Datagrid>
      </ReferenceManyField>
      {/* <AddPrimarySelectionButton /> */}
      <RefreshSecondarySelectionButton/>
    </Tab>
  </TabbedShowLayout>
</Show>
);

export default YachtTemplateDetShow;
