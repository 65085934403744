import React, {useEffect, useRef} from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    NumberInput,
    FormDataConsumer,
    TopToolbar, 
    ListButton, 
    required
  } from "react-admin";
import {GetWeeksInYear, GetCurrentWeekDay, GetDatesInWeek, formatDateYYYYMMDD,convertHourstoMinute} from '../helpers/routines' 
import { Fetch_Get } from '../helpers/getQueries'
import { useForm, useFormState } from 'react-final-form';
import ChevronLeft from '@material-ui/icons/ChevronLeft';  

const TimeSheetCreate = (props)=> {

  const weeksInYearArray = useRef([]);
  const datesInWeekArray = useRef([]);
 
  useEffect(() => {
    //get the current year and week
    let currYear = new Date().getFullYear();
    let currWeek  = (new Date()).getWeek()
   
    //get the dates for the current week
    datesInWeekArray.current = GetDatesInWeek(currWeek, currYear);
    //console.log(`datesInWeekArray: ${JSON.stringify(datesInWeekArray.current)}`)
    //console.log(`datesInWeekArray: ${currYear}-${currWeek}`)

    let weeksInYear = GetWeeksInYear(currYear);
    weeksInYearArray.current = [];
    for(let i=1; i<=weeksInYear; i++){
        let weekcode = `${currYear}${i.toString().length === 1 ? `0${i}` : i}`;
        weeksInYearArray.current.push({id:weekcode,name:`Week# ${i}`,week:i,year:currYear});
    }

  }, []);


  const ResourceLookupField = () => {
      const {change} = useForm();
      const { values: { ResourceKey }} = useFormState({ subscription: { values: true } });
      const resourceObj = useRef({});

      useEffect(() => {
        if(typeof ResourceKey !== 'undefined'){
          Fetch_Get().getResourceById(ResourceKey)
          .then((resource)=> 
          {
            resourceObj.current = resource
           
            change('WorkType',  resource.WorkTypeKey);
            change('DistRule',  resource.DistRule);
            change('DistRule2', resource.DistRule2);
            change('DistRule3', resource.DistRule3);
          })
        }
      }, [change, ResourceKey]);

      return(<div>                  
               <ReferenceInput 
                    label="Work Type" 
                    source="WorkTypeKey" 
                    reference="worktype"
                    validate={requiredValidate}                     
                    >
                    <SelectInput  optionText="Description" defaultValue= {resourceObj.current.WorkTypeKey} />
                </ReferenceInput> 

               <TextInput label="Dist Rule" disabled defaultValue= {resourceObj.current.DistRule} source="DistRule" />
               <TextInput label="Dist Rule 2" disabled defaultValue= {resourceObj.current.DistRule2} source="DistRule2" />
               <TextInput label="Dist Rule 3" disabled defaultValue= {resourceObj.current.DistRule3} source="DistRule3" />
            </div>)
  }

  const ProjectLookupField = () => {
    const {change} = useForm();
    const { values: { PlanningKey }} = useFormState({ subscription: { values: true } });
    const planningObj = useRef({});

    useEffect(() => {
      if(typeof PlanningKey !== 'undefined'){
        Fetch_Get().getPlanningById(PlanningKey)
        .then((planning)=> 
        {
          planningObj.current = planning      
          change('projDesc',  planning.Description);
        })
      }
    }, [change, PlanningKey]);

    return(   
             <TextInput 
              label="Project Description" 
              disabled defaultValue = {planningObj.current.Description} 
              source="projDesc" />            
          )
}

const onChangeWeekDay = (weekday) => {
  var weeksInYearObj = weeksInYearArray.current.filter((x) => x.id === weekday)[0];
  datesInWeekArray.current = GetDatesInWeek(weeksInYearObj.week, weeksInYearObj.year);
  //console.log(`week-year:${weeksInYearObj.week}-${weeksInYearObj.year}`)
  //console.log(`weeksInYearObj:${JSON.stringify(datesInWeekArray.current)}`)
  
}


const transform = (data) => {

  let dataToPost = {...data, 
    HoursWorkedMonday: convertHourstoMinute(data.HoursWorkedMonday),
    HoursWorkedTuesday: convertHourstoMinute(data.HoursWorkedTuesday),
    HoursWorkedWednesday: convertHourstoMinute(data.HoursWorkedWednesday),
    HoursWorkedThursday: convertHourstoMinute(data.HoursWorkedThursday),
    HoursWorkedFriday: convertHourstoMinute(data.HoursWorkedFriday),
    HoursWorkedSaturday: convertHourstoMinute(data.HoursWorkedSaturday), 
    HoursWorkedSunday: convertHourstoMinute(data.HoursWorkedSunday),
    TransDate: formatDateYYYYMMDD(datesInWeekArray.current[0])
  }

  console.log(`dataToPost:${JSON.stringify(dataToPost)}`)

  return dataToPost


};

const validateUserCreation = (values) => {
  const errors = {};
  if (!values.ResourceKey) {
      errors.firstName = ['The Employee Name is required'];
  }
  if (!values.PlanningKey) {
      errors.age = ['The Project code is required'];
  } 
  return errors
};

const TimesheetCreateActions = ({ basePath, data }) => (
  <TopToolbar>
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
  </TopToolbar>
);

const requiredValidate = [required()];

return (
    <Create
      title="Create Timesheet"
      // actions={<TimesheetCreateActions />}
      transform={transform}
      {...props}
    >
      <SimpleForm 
        validate={validateUserCreation}
      >               
                      
            <ReferenceInput 
                label="Employee Name" 
                source="ResourceKey" 
                reference="resource"                     
                sort={{ field: 'EmployeeName', order: 'ASC' }}
                perPage={1000}  
                validate={requiredValidate}
                {...props}
                >
                <AutocompleteInput optionText="EmployeeName"/>
            </ReferenceInput> 
          
            <ResourceLookupField/>                           
              
            <span> 
              <ReferenceInput  
                    label="Project Number" 
                    source="PlanningKey" 
                    reference="planning"
                    perPage={1000}  
                    validate={requiredValidate}
                >
                  <AutocompleteInput optionText="ProjectNumber" />
              </ReferenceInput>      &nbsp; 
              <ProjectLookupField/> 
            </span>
              
            <FormDataConsumer>
            {({ formData, ...rest }) => {

              //get the selected weekday based on the today's date
              var currWeekDay = weeksInYearArray.current.filter((x) => x.id === GetCurrentWeekDay())[0];
              
              //console.log(`currWeekDay:${JSON.stringify(currWeekDay)}`)

              if(currWeekDay){
                return(
                  <AutocompleteInput 
                      label="Week Day"
                      source="WeekCode" 
                      choices={weeksInYearArray.current}
                      optionText="name"
                      optionValue="id"
                      onChange={(e) => onChangeWeekDay(e)}
                      defaultValue={currWeekDay.id}>  
                  </AutocompleteInput>
                );
              }
              
            }}
            </FormDataConsumer>

            <FormDataConsumer>
            {({ formData, ...rest }) => {
              
              if(typeof datesInWeekArray.current !== 'undefined'){
                return(
                  
                  <div>
                    <span><NumberInput defaultValue={0.0} label= {'Mon ' + datesInWeekArray.current[0]} source="HoursWorkedMonday"/> &nbsp;  
                    <NumberInput defaultValue={0.0} label= {'Tue ' + datesInWeekArray.current[1]} source="HoursWorkedTuesday"/> &nbsp;  
                    <NumberInput defaultValue={0.0} label= {'Wed ' + datesInWeekArray.current[2]} source="HoursWorkedWednesday"/></span>

                    <span><NumberInput defaultValue={0.0} label= {'Thu ' + datesInWeekArray.current[3]} source="HoursWorkedThursday"/> &nbsp;  
                    <NumberInput defaultValue={0.0} label= {'Fri ' + datesInWeekArray.current[4]} source="HoursWorkedFriday"/> &nbsp;  
                    <NumberInput defaultValue={0.0} label= {'Sat ' + datesInWeekArray.current[5]} source="HoursWorkedSaturday"/></span>

                    <span><NumberInput defaultValue={0.0} label= {'Sun ' + datesInWeekArray.current[6]} source="HoursWorkedSunday"/></span>
                  </div>
                );
              }
              
            }}
            </FormDataConsumer>
                
      </SimpleForm>  
</Create> 
)
}

export default TimeSheetCreate;