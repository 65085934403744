import React, {useState, useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const SelectedMoodboardFinishesField = ({ record }) => {
    const classes = useStyles();

    const [selectedFinishes, setSelectedFinishes] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(()=> {

        dataProvider.getList('item/applicableitems',
            {         
              sort: { field: "id", order: "ASC" },
              filter: { "item_property": record.item_property},
              pagination: { page: 1, perPage: 100 },
            },
          ).then(({ data }) => {
            setSelectedFinishes(data.map((item) => ({ id: item.item_code, name: item.item_name })));
        })
        .catch(error => {
            
        })   
      
    },[])

    return record ? (
        <span className={classes.main}>
            {record.selectedMoodboardFinishes &&
                record.selectedMoodboardFinishes.map(selectedFinishId => {

                    let selectedFinish;

                    if(selectedFinishes instanceof Array){
                        selectedFinish = selectedFinishes.find(s => s.id === selectedFinishId);
                    }                

                    return selectedFinish ? (
                        <Chip
                            size="small"
                            key={selectedFinish.id}
                            className={classes.chip}
                            label={selectedFinish.name}
                        />
                    ) : null;
                })}
        </span>
    ) : null;
};

SelectedMoodboardFinishesField.defaultProps = {
    addLabel: true,
    source: 'selectedMoodboardFinishes',
};

export default SelectedMoodboardFinishesField;
