import { stringify } from "query-string";
import { fetchUtils } from "ra-core";

var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };

export default (apiUrl, httpClient) => {
  if (httpClient === void 0) {
    httpClient = fetchUtils.fetchJson;
  }

  return {
    getList: function (resource, params) {
      var _a = params.pagination,
        page = _a.page,
        perPage = _a.perPage;
      var _b = params.sort,
        field = _b.field,
        order = _b.order;
      var query = __assign(
        __assign({}, fetchUtils.flattenObject(params.filter)),
        {
          _sort: field,
          _order: order,
          _start: (page - 1) * perPage,
          _end: page * perPage,
        }
      );
      var url = apiUrl + "/" + resource + "?" + stringify(query);
      return httpClient(url).then(function (_a) {
        var headers = _a.headers,
          json = _a.json;
        if (!headers.has("x-total-count")) {
          throw new Error(
            "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
          );
        }
        return {
          data: json,
          total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
        };
      });
    },
    getOne: function (resource, params) {
      return httpClient(apiUrl + "/" + resource + "/" + params.id).then(
        function (_a) {
          var json = _a.json;
          return {
            data: json,
          };
        }
      );
    },
    getMany: function (resource, params) {
      var query = {
        id: params.ids,
      };
      var url = apiUrl + "/" + resource + "?" + stringify(query);
      return httpClient(url).then(function (_a) {
        var json = _a.json;
        return { data: json };
      });
    },
    getManyReference: function (resource, params) {
      var _a;
      var _b = params.pagination,
        page = _b.page,
        perPage = _b.perPage;
      var _c = params.sort,
        field = _c.field,
        order = _c.order;
      var query = __assign(
        __assign({}, fetchUtils.flattenObject(params.filter)),
        ((_a = {}),
        (_a[params.target] = params.id),
        (_a._sort = field),
        (_a._order = order),
        (_a._start = (page - 1) * perPage),
        (_a._end = page * perPage),
        _a)
      );
      var url = apiUrl + "/" + resource + "?" + stringify(query);
      return httpClient(url).then(function (_a) {
        var headers = _a.headers,
          json = _a.json;
        if (!headers.has("x-total-count")) {
          throw new Error(
            "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
          );
        }
        return {
          data: json,
          total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
        };
      });
    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: function (resource, params) {
      return Promise.all(
        params.ids.map(function (id) {
          return httpClient(apiUrl + "/" + resource + "/" + id, {
            method: "PUT",
            body: JSON.stringify(params.data),
          });
        })
      ).then(function (responses) {
        return {
          data: responses.map(function (_a) {
            var json = _a.json;
            return json.id;
          }),
        };
      });
    },
    create: function (resource, params) {
      return httpClient(apiUrl + "/" + resource, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(function (_a) {
        var json = _a.json;
        return {
          data: __assign(__assign({}, params.data), { id: json.id }),
        };
      });
    },
    delete: function (resource, params) {
      return httpClient(apiUrl + "/" + resource + "/" + params.id, {
        method: "DELETE",
      }).then(function (_a) {
        var json = _a.json;
        return { data: json };
      });
    },

    deleteMany: function (resource, params) {
      return Promise.all(
        params.ids.map(function (id) {
          return httpClient(apiUrl + "/" + resource + "/" + id, {
            method: "DELETE",
          });
        })
      ).then(function (responses) {
        return {
          data: responses.map(function (_a) {
            var json = _a.json;
            return json.id;
          }),
        };
      });
    },

    update: (resource, params) => {
      if (resource !== "posts" && typeof params.data.pictures === "undefined") {
        // fallback to the default implementation
        return httpClient(apiUrl + "/" + resource + "/" + params.id, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }).then(function (_a) {
          var json = _a.json;
          return { data: json };
        });
      }

      /**
       * For posts update only, convert uploaded image in base 64 and attach it to
       * the `picture` sent property, with `src` and `title` attributes.
       */

      // Freshly dropped pictures are File objects and must be converted to base64 strings
      if (params.data.pictures) {
        
        // NEW CODE HERE (to upload just one file):
        const newPictures = params.data.pictures;
       
        if (!newPictures.rawFile instanceof File) {
          return Promise.reject("Error: Not an image file..."); // Didn't test this...
        }

        return Promise.resolve(convertFileToBase64(newPictures))
          .then((picture64) => ({
            image_url: picture64,
            fileName: `${newPictures.rawFile.path}`,
          }))
          .then((transformedNewPictures) => {
            
          var data= {
            ...params.data,
            pictures: transformedNewPictures,
          };

          return httpClient(apiUrl + "/" + resource + "/" + params.id, {
              method: "PUT",
              body: JSON.stringify(data),
            }).then(function (_a) {
              var json = _a.json;
              return { data: json };
            })}
          );

      }
    },
  };
};

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
