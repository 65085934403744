import React from 'react';
import {
  Edit,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SimpleForm,
  required
} from 'react-admin';

const MoodboardEdit = props => {
 
  return (
    <Edit  title="Edit Mood Board" {...props}>
      <SimpleForm
      >
       
        <ReferenceInput
          label="Ranges" 
          source="range_code"
          reference="ycttemplaterange"
          validate={required()}
        >
          <AutocompleteInput/>
        </ReferenceInput>

        <TextInput label="Description" source="description" validate={required()}/>
       
      
        
      </SimpleForm>
    </Edit>
  );
};

export default MoodboardEdit;
