import TitleIcon from '@material-ui/icons/Title';

import LocationList from './LocationList';
import LocationEdit from './LocationEdit';
import LocationCreate from './LocationCreate';

export default {
    list: LocationList,
    edit: LocationEdit,
    create: LocationCreate,
    icon: TitleIcon,
};