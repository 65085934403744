import { withStyles } from "@material-ui/core/styles";
import React from "react";
import {
  Datagrid,
  List,
  Responsive,
  ShowButton,
  DeleteButton,
  SimpleList,
  TextField,
  DateField,
} from "react-admin";

const styles = (theme) => ({
  title: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const YachtTemplateList = (props) => (
  <List
    title="Yacht Template List"
    {...props}
    sort={{ field: "id", order: "DESC" }}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => (record ? record.id : 0)}
          tertiaryText={(record) =>
            record && record.yctTmplRange
              ? record.yctTmplRange.name
              : "no record"
          }
          linkType={(record) => (record.canEdit ? "edit" : "show")}
        />
      }
      medium={
        <Datagrid rowClick="edit">
          <TextField label="Project Code" source="proj_code" />
          <TextField label="SAP Project Code" source="sap_proj_code" />
          <TextField label="Project Manager" source="projectManager.name" />
          <TextField label="Model" source="model.name" />
          <TextField label="Ranges" source="yctTmplRange.name" />
          <TextField label="Vessel Name" source="vessel_name" />
          <DateField label="Launch Date" source="launch_date" locales="en-GB" />
          <TextField label="Client" source="client.name" />
          <ShowButton />
          <DeleteButton basePath="/yachttemplate/hdr" />
        </Datagrid>
      }
    />
  </List>
);

export default YachtTemplateList;
