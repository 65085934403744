import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin';

const MoodboardDetShow = props => (
  <Show  title="Display Mood Board line item" {...props}>
    <SimpleShowLayout>
      <TextField label="Ranges" source="yctTmplMoodboardHdr.yctTmplRange.name" />
      <TextField label="Mood Board Description" source="yctTmplMoodboardHdr.description"/>
      <TextField label="Type" source="applicationArea.name"/>  
    </SimpleShowLayout>
  </Show>
);

export default MoodboardDetShow;
