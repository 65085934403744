import React from "react";
import {
  Datagrid,
  List,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  ImageField,
} from "react-admin";
import { useDefineAppLocation } from "@react-admin/ra-navigation";

const YachtTemplateDetList = (props) => {
  //useDefineAppLocation('yachttemplate/hdr.yachttemplate/primary', props);

  return (
    <List title="Yacht Template List" {...props}>
      <Responsive
        small={
          <SimpleList
            linkType="show"
            primaryText={(record) =>
              record && record.yctTmplHdr ? record.yctTmplHdr.id : 0
            }
            tertiaryText={(record) =>
              record && record.applicationArea
                ? record.applicationArea.name
                : ""
            }
          />
        }
        medium={
          <Datagrid rowClick="edit">
            <TextField label="Project Code" source="yctTmplHdr.proj_code" />
            <TextField label="Ranges" source="yctTmplHdr.yctTmplRange.name" />
            <TextField label="Type" source="applicationArea.name" />
            <TextField label="Model" source="yctTmplHdr.model.name" />
            <TextField label="Finishes" source="finish.name" />
            <ImageField
              label="Thumbnail"
              source="finish.thumbnail_url"
              title="image_title"
            />
            <ShowButton />
          </Datagrid>
        }
      />
    </List>
  );
};

export default YachtTemplateDetList;
