import React from "react";
import { Datagrid, TextField, NumberField, ImageField } from "react-admin";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import EnterpriseList from "../../ra-enterprise/List";
import { makeStyles } from "@material-ui/core";
import ItemPropertiesField from "./ItemPropertiesField";

const useStyles = makeStyles((theme) => ({
  nb_commands: { color: "purple" },
  hiddenOnSmallScreens: {
    display: "table-cell",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  tbody: {
    height: 20,
  },
}));

const FinishListColumn = {
  id: <TextField source="id" label="Code" />,
  name: <TextField source="name" label="Description" />,
  itemGroup: <TextField source="itemGroup.name" label="Item Group" />,
  supplier: <TextField source="supplier.name" label="Supplier" />,
  manufacturer: <TextField source="manufacturer.name" label="Manufacturer" />,
  finish_type: <TextField source="finish_type" label="Finish Type" />,
  uom: <TextField source="uom" label="UOM" />,
  price: <NumberField source="price" label="Price" sortable={false} />,
  catalogue_num: <TextField source="catalogue_num" label="Mfr Catalogue No." />,
  range: <TextField source="range" label="Range" />,
  colour: <TextField source="colour" label="Colour" />,
  attr1: <TextField source="attr1" label="Attribute 1" />,
  attr2: <TextField source="attr2" label="Attribute 2" />,
  attr3: <TextField source="attr3" label="Attribute 3" />,
  colour: <TextField source="colour" label="Colour" />,
  thumbnail: (
    <ImageField source="thumbnail_url" label="Thumbnail" title="image_title" />
  ),
};

const FinishList = (props) => {
  useDefineAppLocation("item");
  const classes = useStyles();

  return (
    <EnterpriseList
      {...props}
      classes={classes}
      preferenceKey="finish.list"
      hasColumnsSelector
      defaultColumns={FinishListColumn}
      defaultOmittedColumns={[
        "colour",
        "range",
        "attr1",
        "attr2",
        "attr3",
        "image_title",
      ]}
      hasViewSelector
      sort={{ field: "id", order: "ASC" }}
      perPage={25}
    >
      {({
        columns,
        view,
        hasShow,
        hasList,
        hasEdit,
        //hasCreate,
        hasDelete,
        ...rest
      }) => {
        return (
          <Datagrid rowClick="edit" {...rest}>
            {columns}
          </Datagrid>
        );
      }}
    </EnterpriseList>
  );
};

export default FinishList;
