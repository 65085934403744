import React, { useRef, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateInput,
  NumberField,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  Pagination,
  FormDataConsumer,
} from "react-admin";
import {
  GetDatesInWeek,
  GetCurrentYear,
  GetCurrentWeek,
  formatDateYYYYMMDD,
} from "../helpers/routines";
import { useForm } from "react-final-form";

const ERSSummarisedTimeSheetList = (props) => {
  const datesInWeekArray = useRef([]);

  const TimeSheetFilter = (props) => {
    return (
      <Filter {...props}>
        <div class="header" alwaysOn>
          <ReferenceInput
            label="Employee Name"
            source="ResourceKey"
            reference="resource"
            perPage={1000}
            sort={{ field: "EmployeeName2", order: "ASC" }}
            alwaysOn
          >
            <AutocompleteInput optionText="EmployeeName2" />
          </ReferenceInput>{" "}
          &nbsp;
          <ReferenceInput
            label="Project Code"
            source="PlanningKey"
            reference="planning"
            perPage={1000}
            sort={{ field: "ProjectNumber", order: "ASC" }}
            filter={{ IsClosed: false }}
            alwaysOn
          >
            <AutocompleteInput optionText="ProjectNumber" />
          </ReferenceInput>{" "}
          &nbsp;
          <FormDataConsumer alwaysOn>
            {({ formData, ...rest }) => {
              let currYear =
                formData && formData.WeekCode
                  ? formData.WeekCode.substr(0, 4)
                  : GetCurrentYear;
              let currWeek =
                formData && formData.WeekCode
                  ? formData.WeekCode.substr(4, 2)
                  : GetCurrentWeek - 1;
              datesInWeekArray.current = GetDatesInWeek(currWeek, currYear);

              const { change } = useForm();

              useEffect(() => {
                change(
                  "startDate",
                  datesInWeekArray.current.length !== 0
                    ? formatDateYYYYMMDD(datesInWeekArray.current[0])
                    : null
                );
                change(
                  "endDate",
                  datesInWeekArray.current.length !== 0
                    ? formatDateYYYYMMDD(datesInWeekArray.current[6])
                    : null
                );
              }, [change, datesInWeekArray.current]);

              return (
                <div class="header">
                  <ReferenceInput
                    label="Week #"
                    source="WeekCode"
                    perPage={1000}
                    reference="misc/weekcode"
                    alwaysOn
                  >
                    <AutocompleteInput />
                  </ReferenceInput>{" "}
                  &nbsp;
                  <DateInput
                    label="Start Date"
                    disabled
                    defaultValue={datesInWeekArray.current[0]}
                    source="startDate"
                  />
                  {"   "}
                  &nbsp;
                  <DateInput
                    label="End Date"
                    disabled
                    defaultValue={datesInWeekArray.current[6]}
                    source="endDate"
                  />
                </div>
              );
            }}
          </FormDataConsumer>
        </div>
      </Filter>
    );
  };

  const timeSheetRowStyle = (record, index) => ({
    backgroundColor:
      record.ERSHoursWorked !== record.SAPHoursWorked ? "#ff6e00" : "white",
  });

  const TimeSheetPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  );

  return (
    <List
      filters={<TimeSheetFilter />}
      sort={{ field: "employee_fullname", order: "ASC" }}
      perPage={100}
      pagination={<TimeSheetPagination />}
      debounce={3000}
      {...props}
    >
      <Datagrid rowStyle={timeSheetRowStyle}>
        <TextField label="Employee Id" source="employee_export_code" />
        <TextField label="Employee Name" source="employee_fullname" />
        <TextField label="Job Code" source="job_code_export_code" />
        <TextField label="Job Name" source="job_name" />
        <TextField label="Department" source="department" />
        <TextField label="Week Code" source="WeekCode" />
        <TextField label="Shift" source="Shift" />
        <NumberField label="ERS Hours Worked" source="ERSHoursWorked" />
        <NumberField label="SAP Hours Worked" source="SAPHoursWorked" />
      </Datagrid>
    </List>
  );
};

export default ERSSummarisedTimeSheetList;
