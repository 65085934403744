import React, { useState, useRef, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Box } from "@material-ui/core";

import GetAppIcon from "@material-ui/icons/GetApp";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Title,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  useDataProvider,
  useNotify,
  useRedirect,
  Button,
  required,
} from "react-admin";
import { Form, useForm, useFormState } from "react-final-form";
import { GetDatesInWeek, formatDateYYYYMMDD } from "../helpers/routines";
import { Fetch_Get } from "../helpers/getQueries";

const DownloadTimeSheet = () => {
  const datesInWeekArray = useRef([]);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = async (values) => {
    setLoading(true);
    await sleep(3000);

    const dataToPost = {
      ...values,
      startDate: formatDateYYYYMMDD(values.startDate),
      endDate: formatDateYYYYMMDD(values.endDate),
    };

    dataProvider
      .create("timesheet/DownloadTimesheet", { data: dataToPost })
      .then((response) => {
        redirect("/timesheet");
        notify("Timesheet Download Successful");
      })
      .catch((error) => {
        setLoading(false);
        notify(`No Timesheet Downloaded: ${error.message}`, "warning");
      });
  };

  const onChangeWeekDay = (selectedWeekCode) => {
    let currYear = selectedWeekCode.substr(0, 4);
    let currWeek = selectedWeekCode.substr(4, 2);
    datesInWeekArray.current = GetDatesInWeek(currWeek, currYear);
  };

  const ResourceLookupField = () => {
    const { change } = useForm();
    const {
      values: { resourceKey },
    } = useFormState({ subscription: { values: true } });
    const resourceObj = useRef({});

    useEffect(() => {
      if (typeof resourceKey !== "undefined") {
        Fetch_Get()
          .getResourceById(resourceKey)
          .then((resource) => {
            resourceObj.current = resource;
            change("employeeCode", resource.EmployeeCode);
          });
      }
    }, [change, resourceKey]);

    return (
      <div>
        <TextInput
          label="Employee Code"
          disabled
          defaultValue={resourceObj.current.EmployeeCode}
          source="employeeCode"
        />
      </div>
    );
  };

  const ProjectLookupField = () => {
    const { change } = useForm();
    const {
      values: { planningKey },
    } = useFormState({ subscription: { values: true } });
    const projectObj = useRef({});

    useEffect(() => {
      if (typeof planningKey !== "undefined") {
        Fetch_Get()
          .getPlanningById(planningKey)
          .then((project) => {
            projectObj.current = project;
            change("description", project.Description);
          });
      }
    }, [change, planningKey]);

    return (
      <div>
        <TextInput
          label="Description"
          disabled
          defaultValue={projectObj.current.Description}
          source="description"
        />
      </div>
    );
  };

  const StartEndDateField = () => {
    const { change } = useForm();

    useEffect(() => {
      change(
        "startDate",
        datesInWeekArray.current.length !== 0
          ? datesInWeekArray.current[0]
          : null
      );
      change(
        "endDate",
        datesInWeekArray.current.length !== 0
          ? datesInWeekArray.current[6]
          : null
      );
    }, [change, datesInWeekArray.current]);

    return (
      <span>
        <TextInput
          label="Start Date"
          disabled
          defaultValue={datesInWeekArray.current[0]}
          source="startDate"
        />
        {"   "}
        &nbsp;
        <TextInput
          label="End Date"
          disabled
          defaultValue={datesInWeekArray.current[6]}
          source="endDate"
        />
      </span>
    );
  };

  const requiredValidate = [required()];

  return (
    <Card>
      <Title title="Download Timesheets Here" />
      <CardContent>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <h1>Download Timesheets From ERS Based on the criteria below</h1>

              {/* {loading ? <Loading loadingPrimary="Downloading..."  /> : <span></span>} */}

              {loading ? (
                <CircularProgress loadingPrimary="Downloading..." />
              ) : (
                <span></span>
              )}

              <Box sx={{ width: "30%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <ReferenceInput
                      label="Employee Name"
                      source="resourceKey"
                      reference="resource"
                      perPage={1000}
                      sort={{ field: "EmployeeName", order: "ASC" }}
                    >
                      <AutocompleteInput optionText="EmployeeName" />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ResourceLookupField />
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput
                      label="Project Code"
                      source="planningKey"
                      reference="planning"
                      perPage={1000}
                      sort={{ field: "ProjectNumber", order: "ASC" }}
                      filter={{ IsClosed: false }}
                    >
                      <AutocompleteInput optionText="ProjectNumber" />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ProjectLookupField />
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput
                      label="Week #"
                      source="weekCode"
                      reference="misc/weekcode"
                      perPage={1000}
                      onChange={(e) => onChangeWeekDay(e)}
                      validate={requiredValidate}
                    >
                      <AutocompleteInput />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <StartEndDateField />
                </Grid>
              </Box>

              <div>
                {/*  <StartEndDateField /> <DownloadTimesheetButton formData = {values,submitting,pristine}/> */}
                {/* <div>
           {loading ? <CircularProgress loadingPrimary="Downloading..."  /> : <span></span>} 
           </div> */}
                <Button
                  label="Download"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting || pristine}
                >
                  <GetAppIcon />
                </Button>{" "}
                &nbsp;
                <Button
                  label="Reset"
                  variant="contained"
                  color="primary"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  <RotateLeftIcon />
                </Button>
              </div>

              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </form>
          )}
        />
      </CardContent>
    </Card>
  );
};

export default DownloadTimeSheet;
