export const convertHourstoMinute = (hours) => Math.floor(hours * 60);

Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + parseInt(days));
  return this;
};

Date.prototype.getWeek = function () {
  // Create a copy of this date object
  var target = new Date(this.valueOf());

  // ISO week date weeks start on monday, so correct the day number
  var dayNr = (this.getDay() + 6) % 7;

  // Set the target to the thursday of this week so the
  // target date is in the right year
  target.setDate(target.getDate() - dayNr + 3);

  // ISO 8601 states that week 1 is the week with january 4th in it
  var jan4 = new Date(target.getFullYear(), 0, 4);

  // Number of days between target date and january 4th
  var dayDiff = (target - jan4) / 86400000;

  if (new Date(target.getFullYear(), 0, 1).getDay() < 5) {
    // Calculate week number: Week 1 (january 4th) plus the
    // number of weeks between target date and january 4th
    return 1 + Math.ceil(dayDiff / 7);
  } else {
    // jan 4th is on the next week (so next week is week 1)
    return Math.ceil(dayDiff / 7);
  }
};

// Date.prototype.getWeek = function()
// {
//     var onejan = new Date(this.getFullYear(), 0, 1);
//     return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
// }

export const formatDateDDMMYYYY = (currDate) => {
  let today = new Date(currDate);
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;
  return `${dd}/${mm}/${yyyy}`;
};

export const formatDateMMDDYYYY = (currDate) => {
  let today = new Date(currDate);
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;
  return `${mm}/${dd}/${yyyy}`;
};

export const formatDateYYYYMMDD = (currDate) => {
  let yyyy = currDate.substring(6);
  let mm = currDate.substring(3, 5);
  let dd = currDate.substring(0, 2);

  console.log(`${yyyy}-${mm}-${dd}`);

  return `${yyyy}-${mm}-${dd}`;
};

export const GetDatesInWeek = (w, y) => {
  //var d = (1 + (w - 1) * 7);
  //var currDate = new Date(y, 0, d);
  //var startDate = new Date(y, 0, d);

  var currDate = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = currDate.getDay();
  var startDate = currDate;

  if (dow <= 4) startDate.setDate(currDate.getDate() - currDate.getDay() + 1);
  else startDate.setDate(currDate.getDate() + 8 - currDate.getDay());

  var datesInWeekArray = [];

  //get the day of the current date
  switch (currDate.getDay()) {
    case 1:
      startDate.setDate(currDate.getDate()); //mon
      break;
    case 2:
      startDate.setDate(currDate.getDate() + 6); //tue
      break;
    case 3:
      startDate.setDate(currDate.getDate() + 5); //wed
      break;
    case 4:
      startDate.setDate(currDate.getDate() + 4); //thus
      break;
    case 5:
      startDate.setDate(currDate.getDate() + 3); //fri
      break;
    case 6:
      startDate.setDate(currDate.getDate() + 2); //sat
      break;
    case 7:
      startDate.setDate(currDate.getDate() + 1); //sun
      break;
    default:
      break;
  }

  //console.log(`currDate: ${currDate}`)
  //console.log(`startDate: ${startDate}`)

  //now get the 7 dates starting from Monday
  for (let i = 0; i < 7; i++) {
    var newDate = new Date(startDate);
    newDate.setDate(newDate.getDate() + i);
    //datesInWeekArray.push(formatDateDDMMYYYY(newDate));
    datesInWeekArray.push(new Intl.DateTimeFormat("en-GB").format(newDate));
  }

  //console.log(datesInWeekArray);
  return datesInWeekArray;
};

export const GetWeeksInYear = (year) => {
  var month = 11,
    day = 31,
    week;

  // Find week that 31 Dec is in. If is first week, reduce date until
  // get previous week.
  do {
    week = new Date(year, month, day--).getWeek();
  } while (week === 1);

  return week;
};

export const GetCurrentWeekDay = () => {
  let wk = new Date().getWeek();
  let yy = new Date().getFullYear();
  if (wk < 10) wk = `0${wk}`;
  return `${yy}${wk}`;
};

export const isEmpty = (obj) =>
  Object.keys(obj).length !== 0 && obj.constructor === Object;

export const GetCurrentYear = new Date().getFullYear();

export const GetCurrentWeek = new Date().getWeek();
