import React, {useState, useEffect } from 'react';
import {
  useRedirect,
  useNotify,
} from 'react-admin'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import authProvider from './authProvider'; 


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://knysnayachtco.com/" target="_blank" rel="noopener">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

const SignUp = () => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [id, setId] = useState(0);
  const [FullName, setFullName] = useState("");
  const [FullNameList, setFullNameList] = useState([]); 
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [UserName, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


  const RedirectToSignUpPage = (e) => {
    e.preventDefault();
    redirect('/login');
  }

  useEffect(async () => {
      
    setLoading(true);
    await sleep(1000);

    authProvider.getLogins({ id, UserName, Password })
      .then(res => {                 
          setFullNameList(res);
      }).catch((error) => {    
        setLoading(false);             
    });

    setLoading(false);

}, []);

const onEmployeeChange = (e) => {
    e.preventDefault();

    setFullName(e.target.value);
    var rec = FullNameList.filter(x=> x.id === e.target.value)[0];
    setId(rec.id);
    setFirstName(rec.FirstName);
    setLastName(rec.LastName);
   
}

const handleSubmit = async (e) => {
  e.preventDefault();

  setLoading(true);
  await sleep(1000);

  authProvider.createLogin({ id, UserName, Password })
    .then(res => {            
        redirect('/login');
        notify(res);
    }).catch((error) => {
        setLoading(false);    
        redirect('/login'); 
        notify(error, 'warning');      
    });

};

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
          
         <Grid item xs={12}>
            {loading ? <CircularProgress/> : <span></span>}  
         </Grid>
        
        <form onSubmit={handleSubmit} className={classes.form} Validate>

       
          <Grid container spacing={2}>
          
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="fullname"
                name="fullname"
                select
                label="Select Employee"
                value={FullName}
                onChange={onEmployeeChange}
              >
                {FullNameList.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.FullName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                disabled
                fullWidth
                id="firstName"
                label="First Name"
                value={FirstName}           
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                disabled
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={LastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="User Name"
                name="username"
                autoComplete="username"
                value={UserName}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>         
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
         
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="#" onClick={RedirectToSignUpPage} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default SignUp;