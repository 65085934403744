import React, { useEffect, useState } from "react";
import {
  useLogin,
  useNotify,
  Notification, 
  defaultTheme,
  useRedirect,
} from "react-admin";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://knysnayachtco.com/" target="_blank" rel="noopener">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginPage = ({ theme }) => {
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const redirect = useRedirect();
  const [UserName, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  const handleSubmit = async (e) => {
      e.preventDefault();

      setLoading(true);
      await sleep(1000);

      await login({ UserName, Password })
      .catch((error) => {
          setLoading(false);
          notify(
            typeof error === "string"
              ? error
              : typeof error === "undefined" || !error.message,
            "warning"
          );
      });

  };

  const RedirectToCreateLoginPage = (e) => {
    e.preventDefault();
    redirect('/signup');
  }

  const RedirectToForgotPasswordPage = (e) => {
    e.preventDefault();
    redirect('/forgotpassword');
  }

  
 
  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          
          <Grid item xs={12}>
                {loading ? <CircularProgress/> : <span></span>}  
            </Grid>

          <form onSubmit={handleSubmit} className={classes.form} Validate>    
       
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={UserName}
              autoComplete="UserName"
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="Password"
              label="Password"
              type="Password"
              id="Password"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />         
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" onClick={RedirectToForgotPasswordPage} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" onClick={RedirectToCreateLoginPage} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
      <Notification />
     </ThemeProvider>
  );
};
export default LoginPage;
