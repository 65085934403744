import React from "react";
import {
  Datagrid,
  List,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
} from "react-admin";

const MoodboardDetList = (props) => (
  <List title="Mood Board line items" {...props}>
    <Responsive
      small={
        <SimpleList
          linkType="show"
          primaryText={(record) =>
            record && record.yctTmplMoodboardHdr
              ? record.yctTmplMoodboardHdr.description
              : ""
          }
          secondaryText={(record) =>
            record && record.applicationArea ? record.applicationArea.name : ""
          }
        />
      }
      medium={
        <Datagrid rowClick="edit">
          <TextField
            label="Ranges"
            source="yctTmplMoodboardHdr.yctTmplRange.name"
          />
          <TextField
            label="Mood Board Description"
            source="yctTmplMoodboardHdr.description"
          />
          <TextField label="Type" source="applicationArea.name" />
          <ShowButton />
        </Datagrid>
      }
    />
  </List>
);

export default MoodboardDetList;
