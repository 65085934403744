import React from 'react';
import {
  Create,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  FormDataConsumer,
  SimpleForm,
  required
} from 'react-admin';
import { parse } from 'query-string';
import RichTextInput from 'ra-input-rich-text';

const YachtTemplateDetCreate = props => {
  // Read the yct_hdr_id from the location which is injected by React Router 
  // and passed to our component by react-admin automatically
  const { yct_hdr_id: yct_hdr_id_string } = parse(props.location.search);

  // ra-data-fakerest uses integers as identifiers, we need to parse the querystring
  // We also must ensure we can still create a new comment without having a yct_hdr_id
  // from the url by returning an empty string if yct_hdr_id isn't specified
  const yct_hdr_id = yct_hdr_id_string ? yct_hdr_id_string : '';

  const redirect = yct_hdr_id ? `/yachttemplate/hdr/${yct_hdr_id}/show/yachttemplate/primary` : false;

  console.log(`redirect:${redirect}`);  


  return (
    <Create  title="Create Primary Selection" {...props}>
      <SimpleForm
        defaultValue={{ yct_hdr_id }}
        redirect={redirect}
      >
        <ReferenceInput
          label="Yacht Template" 
          source="yct_hdr_id"
          reference="yachttemplate/hdr"
          
          validate={required()}
        >
          <SelectInput optionText="yctTmplRange.name" />
        </ReferenceInput>

        <ReferenceInput
          label="Type" 
          source="item_property"
          reference="applicationarea"
          sort={{ field: 'name', order: 'ASC' }}
          validate={required()}
        >
          <AutocompleteInput />
        </ReferenceInput>


        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return(
              <ReferenceInput 
              allowEmpty={true}
              label="Finishes" 
              source="finishes_code"
              reference="item/selectedmoodboarditems"
              sort={{ field: 'finishes_code', order: 'ASC' }}
              filter={{ item_property: formData.item_property}}
              perPage={1000}                                        
              >            
              <AutocompleteInput />
          </ReferenceInput>
            )
          }}
        </FormDataConsumer>
        
        <RichTextInput  label="Comments" source="comment" 
            toolbar={[ ['bold', 'italic', 'underline', 'link'] ]} />

      </SimpleForm>
    </Create>
  );
};

export default YachtTemplateDetCreate;
