import config from '../config'

const authProvider = {  

    login: async ({ UserName, Password }) => {

        let res = await fetch(`${config.webApi.URL}/Authentication/Login`,{
            method: 'POST',
            body: JSON.stringify({ UserName, Password }),
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               }});
                    
               try{
                    const { id,FirstName,LastName,FullName,UserName,Email,token,status,title,roles } = await res.json();           
                    
                    if (status < 200 || status >= 300) return Promise.reject(title);
                
                    const auth = {id,FirstName,LastName,FullName,UserName,Email,token,status,title,roles}
                    sessionStorage.setItem('auth', JSON.stringify(auth));
                    sessionStorage.setItem('roles', JSON.stringify(roles));

                    return Promise.resolve({...auth});
               }
               catch(e){
                    Promise.reject(e);
               }

       
               
            },
                  
    logout: () => {
      
        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('roles');
        return Promise.resolve();
    },
    checkError: (error) => {
    
        const status = error.status;
        if (status === 401 || status === 403) {
            sessionStorage.removeItem('auth');
            sessionStorage.removeItem('roles');
            return Promise.reject({ message: true });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => sessionStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),

    getPermissions: () => {      
        const role = sessionStorage.getItem('roles');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: () => {
        try {
           
            const auth = JSON.parse(sessionStorage.getItem('auth'));
           
            return Promise.resolve({ id:auth.id, fullName:auth.FullName });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    createLogin: async ({ id, UserName, Password }) => {

        let res = await fetch(`${config.webApi.URL}/Authentication/UpdateLogin/${id}`,{
            method: 'PUT',
            body: JSON.stringify({ id, UserName, Password }),
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               }});
                    
               try{
                    const { status, title } = await res.json();               
                    if (status < 200 || status >= 300) return Promise.reject(title);
                    return Promise.resolve(title);
               }
               catch(e){                 
                    return Promise.reject(e);
               }
            },
    retrieveForgottenPassword: async ({ UserName }) => {

        let res = await fetch(`${config.webApi.URL}/Authentication/ForgotPassword`,{
            method: 'POST',
            body: JSON.stringify({ UserName }),
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }});
                    
                try{
                    const { status, title } = await res.json();               
                    if (status < 200 || status >= 300) return Promise.reject(title);
                    return Promise.resolve(title);
                }
                catch(e){                 
                    return Promise.reject(e);
                }
            },   

    getLogins: async () => {

        let res = await fetch(`${config.webApi.URL}/authentication/logins?_sort=firstName&_order=ASC&_end=1000`);
        if (res.ok) {
            let data = await res.json();
            return data;     
        }
    },           
    
    
};

export default authProvider;