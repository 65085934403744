import React, {useState, useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const AreaModelsField = ({ record }) => {
    const classes = useStyles();

    const [models, setModels] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(()=> {

        dataProvider.getList('model',
            {         
              sort: { field: "id", order: "ASC" },
              pagination: { page: 1, perPage: 100 },
            },
          ).then(({ data }) => {
            setModels(data.map((item) => ({ id: item.id, name: item.name })));
        })
        .catch(error => {
            
        })   
      
    },[])

    return record ? (
        <span className={classes.main}>
            {record.selectedModels &&
                record.selectedModels.map(modelId => {

                    let model;

                    if(models instanceof Array){
                        model = models.find(s => s.id === modelId);
                    }                

                    return model ? (
                        <Chip
                            size="small"
                            key={model.id}
                            className={classes.chip}
                            label={model.name}
                        />
                    ) : null;
                })}
        </span>
    ) : null;
};

AreaModelsField.defaultProps = {
    addLabel: true,
    source: 'itemProperties',
};

export default AreaModelsField;
