import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
    BooleanField,
  } from "react-admin";

export const PlanningList = props => (
    <List {...props}>
        <Datagrid rowClick={(id, basePath, record) => {
        return `/timesheet?filter=%7B%22PlanningKey%22%3A%22${record.id}%22%7D&order=DESC&page=1&perPage=25&sort=WeekCode`;
      }}>
            <TextField source="id" />
            <TextField source="ProjectNumber" />
            <TextField source="Description" />
            <TextField source="ExternalCode" />
            <TextField source="ReferenceNumber" />
            <TextField source="ProjectType" />
            <TextField source="IsClosedStr" />
            <BooleanField source="IsClosed" />
        </Datagrid>
    </List>
);

export default PlanningList;