import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    FormDataConsumer,
    TopToolbar, 
    ListButton, 
    useNotify, 
    useRefresh
  } from "react-admin";
import ChevronLeft from '@material-ui/icons/ChevronLeft';  

const TimeSheetEdit = (props)=> {

  const notify = useNotify();
  const refresh = useRefresh();

  const TimesheetEditActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    </TopToolbar>
);

const onFailure = (error) => {
  notify(`Could not edit timesheet: ${error.message}`)
  //redirect('/timesheet');
  refresh();
};

    return (
        <Edit
          title="Edit Timesheet"
          // actions={<TimesheetEditActions />}
          undoable={false}
          onFailure={onFailure}
          {...props}
        >
          <SimpleForm>
                <TextInput disabled source="id"/>
                <DateInput disabled source="TransDate" />

                <FormDataConsumer>
                {({ formData, ...rest }) => {
                return(
                    <div>
                    <span><TextInput disabled label="Resource Number" source="Resource.EmployeeName" /> &nbsp;                   
                    <TextInput disabled label="Work Type" source="Resource.WorkTypeKey" /> &nbsp;
                    <TextInput disabled label="Resource Type" source="Resource.ResourceType" /> &nbsp;
                    </span>
                    <span>
                    <TextInput disabled label="Resource Number" source="Resource.ResourceNumber" /> &nbsp;
                    <TextInput disabled label="Distribution Rule" source="Resource.DistRule" /> &nbsp;
                    </span>
                    </div>
                      );
                    }           
                  }
                  </FormDataConsumer>

                <NumberInput source="HoursWorked" format={v => v.toFixed(3)} />
          </SimpleForm>  
    </Edit> 
    )
}

export default TimeSheetEdit;