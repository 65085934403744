import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
  } from "react-admin";
import { makeStyles } from '@material-ui/core';

//import AreasField from './AreasField'  

const useAsideStyles = makeStyles({
    root: {
        marginLeft: '1em',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        width: 150,
    },
});

const Aside = () => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            Click on an employee to see an Editable datagrid of roles
        </div>
    );
};

const useModuleListStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
});

export const RolesList = ({ permissions, ...props }) => {
   
    const classes = useModuleListStyles(props);
return(
    <List  
        title="Roles List"
        aside={<Aside />}
        actions={false}
        className={classes.root}
        sort={{ field: 'FirstName', order: 'ASC' }}
        {...props}
    >
        <Datagrid rowClick="edit">
            <TextField label="First Name" source="FirstName" />   
            <TextField label="Last Name" source="LastName" /> 
            <TextField label="Email" source="Email" /> 
            {/* <AreasField label="Areas"/>                   */}
        </Datagrid>
    </List>
);
};

export default RolesList;