
import React, {useState, useCallback} from "react";
import { useDataProvider, useNotify, useRefresh, Button } from 'react-admin';

const RefreshPrimarySelectionButton = ({ record }) => {
    
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
   
    const memoizedCallback  = useCallback(()=> {

        dataProvider
        .create('yachttemplate/primary/refresh', { data: record })
        .then(response => {
            notify('Refresh Primary Selection Complete');
            setLoading(false)
            refresh();
        })
        .catch(error => {
            // failure side effects go here 
            notify(`Refresh error: ${error.message}`, 'warning');
        });
  
    },[loading])

    const reload = (e) => {          
        setLoading(true);
        memoizedCallback();
    } 
       

    return(<Button
            title="Refresh Primary Selection for Finishing Schedule"
            label="Refresh Primary Selection" 
            onClick= {reload} disabled={loading} 
            
            />);
};

export default RefreshPrimarySelectionButton