import TitleIcon from '@material-ui/icons/Title';

import MoodboardDetList from './MoodboardDetList';
import MoodboardDetCreate from './MoodboardDetCreate';
import MoodboardDetEdit from './MoodboardDetEdit';
import MoodboardDetShow from './MoodboardDetShow';

export default {
    list: MoodboardDetList,
    create: MoodboardDetCreate,
    edit: MoodboardDetEdit,
    show: MoodboardDetShow,
    icon: TitleIcon,
};