import TitleIcon from '@material-ui/icons/Title';

import MoodboardList from './MoodboardList';
import MoodboardEdit from './MoodboardEdit';
import MoodboardCreate from './MoodboardCreate';
import MoodboardShow from './MoodboardShow';

export default {
    list: MoodboardList,
    edit: MoodboardEdit,
    create: MoodboardCreate,
    show: MoodboardShow,
    icon: TitleIcon,
};