import * as React from 'react';
import { Route } from 'react-router-dom';
import DownloadTimeSheet from '../timesheet/DownloadTimeSheet'
import LoginPage from "../authentication/LoginPage";
import SignUp from "../authentication/SignUp";
import ForgotPassword from "../authentication/ForgotPassword";
import ChangePassword from "../authentication/ChangePassword";
import Configuration from "./Configuration";
import PrintFinishingScheduleSecondary from '../reports/finishing_schedule/PrintFinishingScheduleSecondary'
import PrintFinishingScheduleSecondaryGroupByFinishReport from '../reports/finishing_schedule/PrintFinishingScheduleSecondaryGroupByFinishReport'
import PrintFinishingScheduleSecondaryGroupByLocationReport from '../reports/finishing_schedule/PrintFinishingScheduleSecondaryGroupByLocationReport'

export default 
    [
        <Route exact path="/dowloadtimesheet" component={DownloadTimeSheet} />,
        <Route exact path="/configuration" component={Configuration} />,
        <Route exact path="/login" component={LoginPage} noLayout />,
        <Route exact path="/signup" component={SignUp} noLayout />,
        <Route exact path="/forgotpassword" component={ForgotPassword} noLayout />,
        <Route exact path="/changepassword" component={ChangePassword} />,
        <Route exact path="/printfinishingschedule" component={PrintFinishingScheduleSecondary} />,
        <Route exact path="/printfinishingschedulegroupbyfinish" component={PrintFinishingScheduleSecondaryGroupByFinishReport} />,
        <Route exact path="/printfinishingschedulegroupbylocation" component={PrintFinishingScheduleSecondaryGroupByLocationReport} />,
       
    ];
