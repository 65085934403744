import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
    BooleanField,
  } from "react-admin";

export const ResourceList = props => (
    <List  {...props}>
        <Datagrid  rowClick={(id, basePath, record) => {
        return `/timesheet?filter=%7B%22ResourceKey%22%3A%22${record.id}%22%7D&order=DESC&page=1&perPage=25&sort=WeekCode`;
      }}>
            <TextField source="id" />
            <TextField label="Employee Code" source="ResourceNumber" />
            <TextField source="EmployeeName" />         
            <TextField source="WorkTypeKey" />
            <TextField source="ResourceType" />
            <TextField source="DistRule" />
            <TextField source="DistRule2" />
            <TextField source="DistRule3" />
            <TextField source="IsActiveStr" />
            <BooleanField source="IsActive" />
        </Datagrid>
    </List>
);

export default ResourceList;

