import React from 'react';
import {
  Create,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SimpleForm,
  required
} from 'react-admin';

const YachtTemplateCreate = props => {
 
  return (
    <Create  title="Create new Yacht Template" {...props}>
      <SimpleForm
      >
       
        <TextInput label="Project Code" source="proj_code" validate={required()}/>
        <TextInput label="SAP Project Code" source="sap_proj_code" />
       
        <ReferenceInput
          label="Project Manager" 
          source="proj_mgr_id"
          reference="projectmanager"
          sort={{ field: 'FirstName', order: 'ASC' }}
          perPage={1000}
          validate={required()}
        >
          <AutocompleteInput/>
        </ReferenceInput>

        <ReferenceInput
          label="Model" 
          source="model_id"
          reference="model"
        >
          <SelectInput/>
        </ReferenceInput>
        
        <ReferenceInput
          label="Ranges" 
          source="range_code"
          reference="ycttemplaterange"
          validate={required()}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <TextInput label="Vessel Name" source="vessel_name" />
        <DateInput label="Launch Date" source="launch_date" />
       
        <ReferenceInput
          label="Client" 
          source="client_code"
          reference="customer"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
          validate={required()}
        >
          <AutocompleteInput />
        </ReferenceInput>


      </SimpleForm>
    </Create>
  );
};

export default YachtTemplateCreate;
