import TitleIcon from '@material-ui/icons/Title';

import YachtTemplateDetList from './YachtTemplateDetList';
import YachtTemplateDetEdit from './YachtTemplateDetEdit';
import YachtTemplateDetCreate from './YachtTemplateDetCreate';
import YachtTemplateDetShow from './YachtTemplateDetShow';

export default {
    list: YachtTemplateDetList,
    edit: YachtTemplateDetEdit,
    create: YachtTemplateDetCreate,
    show: YachtTemplateDetShow,
    icon: TitleIcon,
};