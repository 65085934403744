import config from '../config'

export const Fetch_Get = () => {

  const auth = JSON.parse(sessionStorage.getItem('auth'));
  const host = config.webApi.URL;
  const token = auth.token;
 
    return {
    
      getResourceById: async (resourceKey) => {
  
        let res = await fetch(
            `${host}/resource/${resourceKey}`,{
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` 
                   }
            }
        );
        if (res.ok) {
          let data = await res.json();
          return data;     
        }
      },
      getPlanningById: async (planningKey) => {
  
        let res = await fetch(
            `${host}/planning/${planningKey}`,{
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` 
                   }
            }
        );
        if (res.ok) {
          let data = await res.json();
          return data;     
        }
      },
      getResources: async () => {
  
        let res = await fetch(
            `${host}/resource?_end=10&_order=ASC&_sort=EmployeeName&_start=0`,{
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` 
                   }
            }
        );
        if (res.ok) {
          let data = await res.json();
          return data;     
        }
      },
      getFinishesById: async (itemCode) => {
  
        let res = await fetch(
            `${host}/item/${itemCode}`,{
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` 
                   }
            }
        );
        if (res.ok) {
          let data = await res.json();
          return data;     
        }
      },

    }
}