import React from 'react';
import {
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SimpleForm,
  required
} from 'react-admin';

const MoodboardCreate = props => {
 
  return (
    <Create  title="Create new Mood Board" {...props}>
      <SimpleForm redirect="list"
      >
       
        <TextInput label="Description" source="description" validate={required()}/>
        <ReferenceInput
          label="Ranges" 
          source="range_code"
          reference="ycttemplaterange"
          validate={required()}
        >
          <AutocompleteInput/>
        </ReferenceInput>

      
        
      </SimpleForm>
    </Create>
  );
};

export default MoodboardCreate;
