import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import {
  Datagrid,
  List,
  Responsive,
  EditButton,
  ShowButton,
  DeleteButton,
  SimpleList,
  TextField,
  useNotify,
  useRefresh,
  useMutation,
} from "react-admin";
import Button from "@material-ui/core/Button";
import FileCopyIcon from "@material-ui/icons/FileCopy";

//import { NIL as NIL_UUID } from 'uuid';

const styles = (theme) => ({
  title: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const MoodboardList = withStyles(styles)(({ classes, ...props }) => {
  const DuplicateMoodboardButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    var today = new Date();
    var todayString = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}${today.getMinutes()}${today.getSeconds()}`;
    const recordTo = {
      ...record,
      description: `Test Moodboard-${todayString}`,
    };

    const [duplicate, { loading }] = useMutation(
      {
        type: "create",
        resource: "yachttemplate/moodboard/duplicate",
        payload: { data: recordTo },
      },
      {
        onSuccess: ({ data }) => {
          //redirect('/comments');
          refresh();
          notify("Moodboard Duplicate completed");
        },
        onFailure: (error) =>
          notify(`No Moodboard Duplicate: ${error.message}`, "warning"),
      }
    );
    return (
      <Button
        startIcon={<FileCopyIcon />}
        disabled={loading}
        color="primary"
        label="Duplicate"
        onClick={() => {
          var yesno = confirm("Do you want to duplicate this moodboard?");
          if (yesno) duplicate(record);
        }}
      >
        Duplicate
      </Button>
    );
  };

  return (
    <List
      title="Mood Boards List"
      sort={{ field: "id", order: "DESC" }}
      {...props}
    >
      <Responsive
        small={
          <SimpleList
            linkType="show"
            primaryText={(record) => (record ? record.description : "")}
            tertiaryText={(record) =>
              record && record.yctTmplRange ? record.yctTmplRange.name : ""
            }
          />
        }
        medium={
          <Datagrid>
            <TextField label="Range" source="yctTmplRange.name" />
            <TextField
              label="Description"
              source="description"
              cellClassName={classes.title}
            />

            <EditButton />
            <ShowButton />
            <DuplicateMoodboardButton />
            <DeleteButton />
          </Datagrid>
        }
      />
    </List>
  );
});

export default MoodboardList;
