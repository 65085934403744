import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required
  } from "react-admin";

const LocationEdit = (props)=> {

    const requiredValidate = [required()];

return (
    <Edit
      title="Edit Yacht Template Locations"  
      {...props}
    >
      <SimpleForm 
      >            
        <TextInput label="Code" source="id"  validate={requiredValidate}/>
        <TextInput label="Description" source="name"  validate={requiredValidate}/>
      </SimpleForm>  
</Edit> 
    )   
}

export default LocationEdit;