import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import { ToggleThemeButton} from '@react-admin/ra-preferences';

import Typography from '@material-ui/core/Typography';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Theme } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

import Logo from './Logo';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingLeft: theme.spacing(1),
    },
    logo: {
        padding: theme.spacing(0, 2),
    },
}));

const ConfigurationMenu = forwardRef((props, ref) => {
  
    return (

        <div>
             {/* <MenuItemLink
                ref={ref}
                to="/configuration"
                primaryText='Configuration'
                leftIcon={<SettingsIcon />}
                onClick={props.onClick}
                sidebarIsOpen
            /> */}
             <MenuItemLink
                ref={ref}
                to="/changepassword"
                primaryText='Change Password'
                leftIcon={<LockOpenIcon />}
                onClick={props.onClick}
                sidebarIsOpen
            />
        </div>
       
        
    );
});

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const CustomAppBar = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );

    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
             {isXSmall ? (
                <>
                    {location.pathname === '/' && (
                        <Logo className={classes.logo} />
                    )}
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                </>
            ) : (
                <>
            <Logo className={classes.logo} />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            <ToggleThemeButton />
            </>
        )}
        </AppBar>
    );
};

export default CustomAppBar;
