import React, {useState, useEffect} from 'react';
import {
  Edit,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  FormDataConsumer,
  SimpleForm,
  NumberInput,
  required,
  useDataProvider
} from 'react-admin';
import { CircularProgress} from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import { useLocation } from 'react-router';
import {Fetch_Get} from '../../helpers/getQueries'
import { useFormState } from 'react-final-form'

const YachtTemplateDet2Edit = props => {

  const dataProvider = useDataProvider();
  const location = useLocation();
  const [redirect, setRedirect] = useState();
  
  useEffect(()=> {
    const lastBackLash = location.pathname.lastIndexOf("/");
    const sec_selection_id = location.pathname.substr(lastBackLash+1);

    dataProvider.getOne('YachtTemplate/Secondary',{ id: sec_selection_id})
      .then(({ data }) => {
        setRedirect(`/yachttemplate/primary/${data.yct_det_id}/show/yachttemplate/secondary`);
      })
    .catch(error => { })   
  },[redirect])


  const PhotoField = () => {

    const { values: { finishes_code,thumbnail_url }} = useFormState({ subscription: { values: true } });  
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    const [finishObj, setFinishObj] = useState({});
    const [loading, setLoading] = useState(false);

    const style = {
      image : {
        width: '200pt',
        height: '200pt'
      }
    }

    useEffect(async() => {
         
      if(finishes_code !== '' && typeof finishes_code !== 'undefined'){
        
        setLoading(true);
        //await sleep(1000);
        
        await Fetch_Get().getFinishesById(finishes_code)
        .then((finish)=> {        
          setFinishObj(finish)   
          setLoading(false);
          console.log(JSON.stringify(finishObj));
        });

      }
    }, [finishes_code]);

    return( 
      
      <div>

      {loading ? <div><CircularProgress loadingPrimary="Downloading..."  /></div> : <span></span>} 
      

      {typeof finishObj !== 'undefined' && Object.keys(finishObj).length !== 0 && finishObj.thumbnail_url !== '' ? 
      <img src={`${finishObj.thumbnail_url}`} style={style.image} alt="" />:
      <img src={`${thumbnail_url}`} style={style.image} alt="" />  }
    
      </div>

    )  

   }


  return (
    <Edit  title="Edit Secondary Selection" {...props}>
      <SimpleForm 
        redirect={redirect}
      >
      
        <TextInput label="Ranges" disabled source="yctTmplDet.yctTmplHdr.yctTmplRange.name" />
        <TextInput label="Type" disabled source="yctTmplDet.applicationArea.name" />
        <TextInput label="Model" disabled source="yctTmplDet.yctTmplHdr.model.name" />
        <TextInput label="Area" disabled source="area.name" />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return(
              <ReferenceInput 
              allowEmpty={true}
              label="Finishes" 
              source="finishes_code"
              reference="item/selectedmoodboarditems"
              sort={{ field: 'finishes_code', order: 'ASC' }}
              filter={{ item_property: formData.item_property,range_code: formData.yctTmplDet.yctTmplHdr.yctTmplRange.id}}
              perPage={1000}    
              defaultValue={formData.yctTmplDet.finishes_code}                                    
              >            
              <AutocompleteInput/>
          </ReferenceInput>
            )
          }}
        </FormDataConsumer>

        <PhotoField/>

        <ReferenceInput
          label="Location" 
          source="loc_code"
          reference="ycttemplatelocation"        
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
        >
          <AutocompleteInput />
        </ReferenceInput>
       
        
        <NumberInput label="Quantity" source="quantity" />
        <NumberInput disabled label="Price" source="price" />

        <FormDataConsumer>
            {({
                formData, ...rest
            }) => {
                if (typeof formData !== "undefined") {
                    formData.total = formData.quantity * formData.price;
                return (
                    <NumberInput
                    disabled
                    defaultValue={formData.total}
                    label="Total"
                    source="total"
                    />
                );
                } else {
                return (
                    <NumberInput
                    disabled
                    label="Total"
                    source="total"
                    />
                );
                }
            }}
        </FormDataConsumer>

        <RichTextInput  label="Comments" source="comment" 
            toolbar={[ ['bold', 'italic', 'underline', 'link'] ]} />

      </SimpleForm>
    </Edit>
  );
};

export default YachtTemplateDet2Edit;
