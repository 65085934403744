import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

const Logo = (props) => {
    const theme = useTheme();
    return (
        <svg
        width={234.532}
        height={20.475}
        viewBox="0 0 162.053 22.417"
        {...props}
    >
        <g
            aria-label="~Knysna Yacht E-Portal~"
            style={{
                lineHeight: 1.25,
            }}
            fontWeight={400}
            fontSize={15.056}
            fontFamily="Arial Black"
            letterSpacing={0}
            wordSpacing={0}
            strokeWidth={0.265}
            fill={theme.palette.secondary.light}
        >
            <text x="0" y="15" fill="blue">~Knysna Yacht E-Portal~</text> 
    </g>
    </svg> 
    );
};

export default Logo;
