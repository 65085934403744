import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
    NumberField,
    ReferenceInput,
    SelectInput,
    Filter,
    required,
  } from "react-admin";

class SecondarySelectionGroupByLocationReport extends React.PureComponent {

    render() {
 
     const SecondarySelectionFilter = (props) => {
    
        return(
        <Filter {...props}>
            <ReferenceInput 
                alwaysOn
                label="Project Code" 
                source="yct_hdr_id" 
                reference="yachttemplate/finishingschedule/hdr"      
                perPage={1000}               
                sort={{ field: 'proj_code', order: 'ASC' }}
                validate={[required()]} 
                >
                <SelectInput optionText="proj_code"/>
            </ReferenceInput> 
    
        </Filter>)
        }; 
    

    return (
        <List 
            basePath="/yachttemplate/finishingschedule/secondary/groupbylocation"
            resource="yachttemplate/finishingschedule/secondary/groupbylocation"
            filters={<SecondarySelectionFilter />} 
            sort={{ field: 'item_property', order: 'ASC'}}
            perPage={1000}
            exporter={false}
            >
          <Datagrid>
              <TextField label="Primary Selection" source="yctTmplDet.yctTmplHdr.yctTmplRange.name"/>
              <TextField label="Type" source="applicationArea.name"/>            
              <TextField label="Model" source="yctTmplDet.yctTmplHdr.model.name"/>
              <TextField label="Area" source="area.name"/> 
              <TextField label="Finishes" source="finish.name"/> 
              <TextField label="Location" source="yctTmplLocation.name"/> 
              <NumberField  label="Quantity" source="quantity"/> 
              <NumberField  label="Total" source="total"/>       
          </Datagrid>  
    </List> 
    );
  } 
}

export default SecondarySelectionGroupByLocationReport;