import React, {useState, useEffect} from 'react';
import {
  Edit,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  SimpleForm,
  required,
  useDataProvider
} from 'react-admin';
import { useLocation } from 'react-router';

const MoodboardDetEdit = props => {

  const dataProvider = useDataProvider();
  const location = useLocation();
  const [redirect, setRedirect] = useState();
  
  useEffect(()=> {
    const lastBackLash = location.pathname.lastIndexOf("/");
    const moodboard_det_id = location.pathname.substr(lastBackLash+1);
   
    dataProvider.getOne('YachtTemplate/Moodboarddet',{ id: moodboard_det_id})
      .then(({ data }) => {
        setRedirect(`/yachttemplate/moodboard/${data.moodboard_hdr_id}/show/yachttemplate/moodboarddet`);
        console.log(redirect);
      })
    .catch(error => { })   
  },[redirect])


  return (
    <Edit  title="Edit Mood Board line item" {...props}>
      <SimpleForm
        redirect={redirect}
      >      
        <TextInput disabled label="Ranges" source="yctTmplMoodboardHdr.yctTmplRange.name"/>
        <TextInput disabled label="Description" source="yctTmplMoodboardHdr.description"/>
        <TextInput disabled label="Type" source="applicationArea.name"/>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return(
              <ReferenceArrayInput 
              label="Selected Finishes"
              source="selectedMoodboardFinishes" 
              reference="item/applicableitems"
              sort={{ field: 'item_name', order: 'ASC' }}
              filter={{ item_property: formData.item_property}}
              perPage={1000}                        
              fullWidth             
              >             
              <SelectArrayInput optionValue="item_code" optionText="item_name"/>
          </ReferenceArrayInput>
            )
           
            }}
        </FormDataConsumer>

      
      </SimpleForm>
    </Edit>
  );
};

export default MoodboardDetEdit;
