import TitleIcon from '@material-ui/icons/Title';

import CategoryList from './CategoryList';
import CategoryEdit from './CategoryEdit';
import CategoryCreate from './CategoryCreate';

export default {
    list: CategoryList,
    edit: CategoryEdit,
    create: CategoryCreate,
    icon: TitleIcon,
};