import AVTimerIcon from '@material-ui/icons/AvTimer';
import TimeSheetList from './TimeSheetList';
import TimeSheetEdit from './TimeSheetEdit';
import TimeSheetCreate from './TimeSheetCreate';

export default {
    list: TimeSheetList,
    edit: TimeSheetEdit,
    create: TimeSheetCreate,
    icon: AVTimerIcon,
};