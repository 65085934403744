import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  Pagination,
} from "react-admin";

const ERSTimeSheetList = (props) => {
  const TimeSheetFilter = (props) => {
    return (
      <Filter {...props}>
        <ReferenceInput
          label="Employee Name"
          source="ResourceKey"
          reference="resource"
          perPage={1000}
          sort={{ field: "EmployeeName2", order: "ASC" }}
          alwaysOn
        >
          <AutocompleteInput optionText="EmployeeName2" />
        </ReferenceInput>

        <ReferenceInput
          label="Project Code"
          source="PlanningKey"
          reference="planning"
          perPage={1000}
          sort={{ field: "ProjectNumber", order: "ASC" }}
          filter={{ IsClosed: false }}
          alwaysOn
        >
          <AutocompleteInput optionText="ProjectNumber" />
        </ReferenceInput>

        <DateInput label="From Date" source="startDate" alwaysOn />
        <DateInput label="To Date" source="endDate" alwaysOn />
      </Filter>
    );
  };

  const timeSheetRowStyle = (record, index) => ({
    backgroundColor:
      record.ERSHoursWorked !== record.SAPHoursWorked ? "#ff6e00" : "white",
  });

  const TimeSheetPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  );

  return (
    <List
      filters={<TimeSheetFilter />}
      sort={{ field: "employee_fullname", order: "ASC" }}
      perPage={100}
      pagination={<TimeSheetPagination />}
      debounce={3000}
      {...props}
    >
      <Datagrid rowStyle={timeSheetRowStyle}>
        <TextField label="Employee Id" source="employee_export_code" />
        <TextField label="Employee Name" source="employee_fullname" />
        <DateField
          label="Transaction Date"
          source="TransDate"
          locales="en-GB"
        />
        <TextField label="Job Code" source="job_code_export_code" />
        <TextField label="Job Name" source="job_name" />
        <TextField label="Department" source="department" />
        <TextField label="Week Code" source="WeekCode" />
        <TextField label="Shift" source="Shift" />
        <NumberField label="ERS Hours Worked" source="ERSHoursWorked" />
        <NumberField label="SAP Hours Worked" source="SAPHoursWorked" />
      </Datagrid>
    </List>
  );
};

export default ERSTimeSheetList;
