import React, {useState, useRef, useEffect}from 'react';
import {
  Create,
  ReferenceInput,
  TextInput,
  SelectInput,
  SimpleForm,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  required,
  useDataProvider
} from 'react-admin';
import { parse } from 'query-string';
import { useForm } from 'react-final-form'
import  {isEmpty}  from '../../helpers/routines' 


const MoodboardDetCreate = props => {
  const { moodboard_hdr_id: moodboard_hdr_id_string } = parse(props.location.search);
  const moodboard_hdr_id = moodboard_hdr_id_string ? moodboard_hdr_id_string : '';
  const redirect = moodboard_hdr_id ? `/yachttemplate/moodboard/${moodboard_hdr_id}/show/yachttemplate/moodboarddet` : false;

  const RangeLookupField = () => {
    const dataProvider = useDataProvider();
    const {change} = useForm();
    const moodbdObj = useRef({});

    useEffect(() => {
        dataProvider.getOne('YachtTemplate/moodboard',{ id: moodboard_hdr_id})
        .then(({ data }) => {
        
          moodbdObj.current = data        
          change('yctTmplRange.name',  moodbdObj.current.yctTmplRange.name);    
          change('description',  moodbdObj.current.description);

      }).catch(error => { })    
  }, [change]);

    return (<span>                  
             <TextInput label="Range" 
                  disabled 
                  source="yctTmplRange.name"/>  &nbsp;&nbsp;
              <TextInput label="Description" 
                  disabled 
                  source="description"/>
           </span>)
   }

  return (
    <Create  title="Create Mood Board line item" {...props}>
      <SimpleForm
        defaultValue={{ moodboard_hdr_id }}
        redirect={redirect}>    

        <RangeLookupField/>
        
        <ReferenceInput
          label="Type" 
          source="item_property"
          reference="applicationarea"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
          validate={required()}
        >
          <SelectInput />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return(
              <ReferenceArrayInput 
              label="Selected Finishes"
              source="selectedMoodboardFinishes" 
              reference="item/applicableitems"
              sort={{ field: 'item_name', order: 'ASC' }}
              filter={{ item_property: formData.item_property}}
              perPage={1000}
              fullWidth
              >
              
              <SelectArrayInput  optionValue="item_code" optionText="item_name"/>
          </ReferenceArrayInput>
            )
           
            }}
        </FormDataConsumer>
        
      </SimpleForm>
    </Create>
  );
};

export default MoodboardDetCreate;
