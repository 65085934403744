import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required
  } from "react-admin";

const ModuleCreate = (props)=> {

    const requiredValidate = [required()];

return (
    <Create
      title="Create Menu"  
      {...props}
    >
      <SimpleForm redirect="list"
      >            
        <TextInput label="Menu" source="name"  validate={requiredValidate}/>
      </SimpleForm>  
</Create> 
)
}

export default ModuleCreate;