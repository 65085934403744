import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import  SecondarySelectionGroupByFinishReport  from './SecondarySelectionGroupByFinishReport';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';


const PrintFinishingScheduleSecondaryGroupByFinishReport = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
       <br/>
       <Button 
          startIcon={<PrintIcon />}  
          color="primary"
          onClick={handlePrint}>
          Print this out!
      </Button> 
      <br/> <br/>
      <SecondarySelectionGroupByFinishReport ref={componentRef} />
    </div>
  );
};

export default PrintFinishingScheduleSecondaryGroupByFinishReport