import React from "react";
import {
  Datagrid,
  List,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  ImageField,
} from "react-admin";

const YachtTemplateDet2List = (props) => (
  <List title="Yacht Template List" {...props}>
    <Responsive
      small={
        <SimpleList
          linkType="show"
          primaryText={(record) =>
            record && record.yctTmplDet ? record.yctTmplDet.id : 0
          }
          tertiaryText={(record) =>
            record && record.applicationArea ? record.applicationArea.name : ""
          }
        />
      }
      medium={
        <Datagrid rowClick="edit">
          <TextField
            label="Ranges"
            source="yctTmplDet.yctTmplHdr.yctTmplRange.name"
          />
          <TextField label="Type" source="applicationArea.name" />
          <TextField label="Model" source="yctTmplDet.yctTmplHdr.model.name" />
          <TextField label="Finishes" source="finish.name" />
          <ImageField
            label="Thumbnail"
            source="finish.thumbnail_url"
            title="image_title"
          />
          <ShowButton />
        </Datagrid>
      }
    />
  </List>
);

export default YachtTemplateDet2List;
