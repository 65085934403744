import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';

import YachtTemplateList from './YachtTemplateList';
import YachtTemplateEdit from './YachtTemplateEdit';
import YachtTemplateCreate from './YachtTemplateCreate';
import YachtTemplateShow from './YachtTemplateShow';

export default {
    list: YachtTemplateList,
    edit: YachtTemplateEdit,
    create: YachtTemplateCreate,
    show: YachtTemplateShow,
    icon: DirectionsBoatIcon,
};