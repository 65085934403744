import React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceManyField,
    TextInput,
    TextField,
    required,
  } from "react-admin";
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

const ModuleDetForm = props => (
    <RowForm {...props}>
        <TextInput label="Menu Item"source="subject" validate={required()} />
    </RowForm>
);

const ModuleEdit = ({ id, ...props })=> {

    const requiredValidate = [required()];

return (
    <Edit
      title="Edit Menu"  
      id={id}
      {...props} 
    >
      <SimpleForm      
      >            
        <TextInput label="Menu" source="name"  validate={requiredValidate}/>
        <ReferenceManyField
            fullWidth
            label="Menu Details"
            reference="module/detail"
            target="module_id"
        >
            <EditableDatagrid
                undoable
                createForm={
                    <ModuleDetForm initialValues={{ module_id: id }} />
                }
                editForm={<ModuleDetForm />}
                rowClick="edit"
            >
                <TextField source="subject" label="Menu Item" />
            </EditableDatagrid>
        </ReferenceManyField>
      </SimpleForm>  
</Edit> 
    )   
}

export default ModuleEdit;