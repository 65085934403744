import React from "react";
import { useSelector } from "react-redux";

import {
  MultiLevelMenu,
  MenuItem,
  MenuItemCategory,
  Menu as NavigationMenu,
} from "@react-admin/ra-navigation";

import {
  useMediaQuery,
  CardContent,
  Typography,
  withStyles,
  Badge,
  Box,
} from "@material-ui/core";

import DashboardIcon from "@material-ui/icons/Dashboard";

//timesheet
import TimerIcon from "@material-ui/icons/Timer"; //sap timesheet
import AlarmOnIcon from "@material-ui/icons/AlarmOn"; //erp timesheet
import AvTimerIcon from "@material-ui/icons/AvTimer"; //erp summarised timesheet

//miscelaneous
import PeopleIcon from "@material-ui/icons/People"; //resource
import AssessmentIcon from "@material-ui/icons/Assessment"; //project
import ViewModuleIcon from "@material-ui/icons/ViewModule"; //modules
import AssignmentIcon from "@material-ui/icons/Assignment"; //roles

//finishing schedule
import BuildIcon from "@material-ui/icons/Build"; //finishing schedule
import DomainIcon from "@material-ui/icons/Domain"; //Area
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode"; //model
import FlipToBackIcon from "@material-ui/icons/FlipToBack"; //range
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications"; //types
import LocationOnIcon from "@material-ui/icons/LocationOn"; //location
import ViewColumnIcon from "@material-ui/icons/ViewColumn"; //finishes
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite"; //mood board

//report
import PrintIcon from "@material-ui/icons/Print";

import timesheet from "../timesheet";
import planning from "../planning";
import yachttemplate from "../yacht_template";

import MobileMenu from "./MobileMenu";
//import {AdminPerm, SAPTimesheetPerm, ERSTimesheetPerm, IsVisibleTimesheetMenu} from './menuPerms'

const StyledBadgeForText = withStyles((theme) => ({
  badge: {
    top: 13,
    right: 13,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const Menu = ({ logout, onMenuClick }) => {
  useSelector((state) => state.theme); // force rerender on theme change
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return isXSmall ? (
    <MobileMenu logout={logout} onMenuClick={onMenuClick} />
  ) : (
    <DesktopMenu onMenuClick={onMenuClick} />
  );

  //return <MobileMenu logout={logout} onMenuClick={onMenuClick} />
};

export default Menu;

const DesktopMenu = ({ onMenuClick }) => {
  return (
    <MultiLevelMenu variant="categories">
      <MenuItemCategory
        name="dashboard"
        to="/"
        exact
        icon={<DashboardIcon />}
        onClick={onMenuClick}
        label="Dashboard"
      />
      <TimesheetMenuItem onMenuClick={onMenuClick} />
      <MenuItemCategory
        name="Miscelaneous"
        icon={<planning.icon />}
        onClick={onMenuClick}
        label="Miscelaneous"
      >
        <CardContent>
          <MiscMenu onMenuClick={onMenuClick} />
        </CardContent>
      </MenuItemCategory>
      <MenuItemCategory
        name="yachttemplate"
        icon={<yachttemplate.icon />}
        onClick={onMenuClick}
        label="Yacht Template"
      >
        <YachtMenu onMenuClick={onMenuClick} />
      </MenuItemCategory>
      <MenuItemCategory
        name="report"
        icon={<AssessmentIcon />}
        onClick={onMenuClick}
        label="Reports"
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Print Finishing Schedule
          </Typography>{" "}
          <NavigationMenu>
            <MenuItem
              name="printfinishingschedule"
              to={`/printfinishingschedule`}
              icon={<PrintIcon />}
              onClick={onMenuClick}
              label="Print fin schd (sec. sel with qty)"
            />
            <MenuItem
              name="printfinishingschedulegroupbyfinish"
              to={`/printfinishingschedulegroupbyfinish`}
              icon={<PrintIcon />}
              onClick={onMenuClick}
              label="Print fin schd (summarized by finishes)"
            />
            <MenuItem
              name="printfinishingschedulegroupbylocation"
              to={"/printfinishingschedulegroupbylocation"}
              icon={<PrintIcon />}
              onClick={onMenuClick}
              label="Print fin schd (summarized by locations)"
            />
          </NavigationMenu>
        </CardContent>
      </MenuItemCategory>
    </MultiLevelMenu>
  );
};

const TimesheetMenuItem = ({ onMenuClick }) => {
  return (
    <StyledBadgeForText color="primary">
      <MenuItemCategory
        name="Timesheets"
        icon={<timesheet.icon fontSize="large" />}
        onClick={onMenuClick}
        label="Timesheets"
        data-testid="commands-menu"
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Timesheets
          </Typography>
          <NavigationMenu>
            <StyledBadgeForText color="primary">
              <MenuItem
                name="saptimesheet"
                to="/timesheet"
                icon={<TimerIcon />}
                onClick={onMenuClick}
                label="SAP Timesheet"
              />
            </StyledBadgeForText>
            <MenuItem
              name="erstimesheet"
              to="/erstimesheet"
              icon={<AlarmOnIcon />}
              onClick={onMenuClick}
              label="ERS Timesheet"
            />
            <MenuItem
              name="ERS Summarized Timesheet"
              to="/erssummarizedtimesheet"
              icon={<AvTimerIcon />}
              onClick={onMenuClick}
              label="ERS Summarized Timesheet"
            />
          </NavigationMenu>
        </CardContent>
      </MenuItemCategory>
    </StyledBadgeForText>
  );
};

const MiscMenu = (props) => {
  const { onMenuClick } = props;

  return (
    <Box display="flex" justifyContent="space-between">
      <Box marginRight={2}>
        <Typography variant="h6" gutterBottom>
          Miscelaneous
        </Typography>
        <NavigationMenu>
          <MenuItem
            name="resource"
            to="/resource"
            icon={<PeopleIcon />}
            onClick={onMenuClick}
            label="Resources"
          />
          <MenuItem
            name="planning"
            to="/planning"
            icon={<AssessmentIcon />}
            onClick={onMenuClick}
            label="Projects"
          />
          <MenuItem
            name="module"
            to="/module"
            icon={<ViewModuleIcon />}
            onClick={onMenuClick}
            label="Modules"
          />
          <MenuItem
            name="employee"
            to="/employee"
            icon={<AssignmentIcon />}
            onClick={onMenuClick}
            label="Employee Roles"
          />
        </NavigationMenu>
      </Box>
    </Box>
  );
};

const YachtMenu = ({ onMenuClick }) => {
  const yachtTemplateConfig = [
    {
      id: "area",
      caption: "Area",
      url: "area",
      icon: <DomainIcon />,
    },
    {
      id: "model",
      caption: "Model",
      url: "model",
      icon: <DeveloperModeIcon />,
    },
    {
      id: "range",
      caption: "Ranges",
      url: "ycttemplaterange",
      icon: <FlipToBackIcon />,
    },
    {
      id: "type",
      caption: "Types",
      url: "applicationarea",
      icon: <SettingsApplicationsIcon />,
    },
    {
      id: "location",
      caption: "Locations",
      url: "ycttemplatelocation",
      icon: <LocationOnIcon />,
    },
    {
      id: "item",
      caption: "Finishes",
      url: "item",
      icon: <ViewColumnIcon />,
    },
    {
      id: "moodboard",
      caption: "Mood Board",
      url: "yachttemplate/moodboard",
      icon: <SettingsInputCompositeIcon />,
    },
  ];

  return (
    <CardContent>
      <Box display="flex" justifyContent="space-between">
        <Box marginRight={2}>
          <Typography variant="h6" gutterBottom>
            Yacht Template
          </Typography>
          <NavigationMenu>
            <MenuItem
              name="finishingschd"
              to={`/yachttemplate/hdr`}
              onClick={onMenuClick}
              label="Finishing Schedule"
              icon={<BuildIcon />}
            />
          </NavigationMenu>
          <Typography variant="h6" gutterBottom>
            Configuration
          </Typography>
          <NavigationMenu>
            {yachtTemplateConfig.map((config) => (
              <MenuItem
                key={config.id}
                name={config.id}
                to={`/${config.url}`}
                onClick={onMenuClick}
                label={config.caption}
                icon={config.icon}
              />
            ))}
          </NavigationMenu>
        </Box>
      </Box>
    </CardContent>
  );
};
