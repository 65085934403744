import TitleIcon from '@material-ui/icons/Title';

import YachtTemplateDet2List from './YachtTemplateDet2List';
import YachtTemplateDet2Edit from './YachtTemplateDet2Edit';
import YachtTemplateDet2Create from './YachtTemplateDet2Create';
import YachtTemplateDet2Show from './YachtTemplateDet2Show';

export default {
    list: YachtTemplateDet2List,
    edit: YachtTemplateDet2Edit,
    //create: YachtTemplateDet2Create,
    show: YachtTemplateDet2Show,
    icon: TitleIcon,
};