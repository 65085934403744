import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
    EditButton,
    DeleteButton,
  } from "react-admin";

export const CategoryList = props => (
    <List  title="Yacht Template Categories List" {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Code" source="id" />
            <TextField label="Description" source="name" />       
            <EditButton basePath='/ycttemplatecategory'/>
            <DeleteButton basePath='/ycttemplatecategory'/>            
        </Datagrid>
    </List>
);

export default CategoryList;