import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
  DeleteButton,
  Filter,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  useRedirect,
  CloneButton,
  Pagination,
} from "react-admin";
import TimesheetDownloadIcon from "@material-ui/icons/CloudDownload";

const TimeSheetList = (props) => {
  const redirect = useRedirect();

  const ListActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      basePath,
      showFilter,
      total,
    } = useListContext();

    const ShowDownloadPage = () => {
      redirect("/dowloadtimesheet");
    };

    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        />
        {/* Add your custom actions */}
        <Button onClick={() => ShowDownloadPage()} label="Download Timesheets">
          <TimesheetDownloadIcon />
        </Button>
      </TopToolbar>
    );
  };

  const TimeSheetFilter = (props) => {
    return (
      <Filter {...props}>
        <ReferenceInput
          label="Employee Name"
          source="ResourceKey"
          reference="resource"
          perPage={1000}
          sort={{ field: "EmployeeName", order: "ASC" }}
          alwaysOn
        >
          <AutocompleteInput optionText="EmployeeName" />
        </ReferenceInput>

        <ReferenceInput
          label="Project Code"
          source="PlanningKey"
          reference="planning"
          perPage={1000}
          sort={{ field: "ProjectNumber", order: "ASC" }}
          filter={{ IsClosed: false }}
          alwaysOn
        >
          <AutocompleteInput optionText="ProjectNumber" />
        </ReferenceInput>

        <ReferenceInput
          label="Week #"
          source="WeekCode"
          perPage={1000}
          reference="misc/weekcode"
          alwaysOn
        >
          <AutocompleteInput />
        </ReferenceInput>
      </Filter>
    );
  };

  const TimeSheetPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  );

  return (
    <List
      filters={<TimeSheetFilter />}
      actions={<ListActions />}
      sort={{ field: "WeekCode", order: "DESC" }}
      perPage={100}
      pagination={<TimeSheetPagination />}
      debounce={3000}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField
          label="Project Number"
          source="PlanningKey"
          reference="planning"
        >
          <TextField source="ProjectNumber" />
        </ReferenceField>
        <ReferenceField
          label="Employee Number"
          source="ResourceKey"
          reference="resource"
        >
          <TextField source="ResourceNumber" />
        </ReferenceField>
        <ReferenceField
          label="Employee Name"
          source="ResourceKey"
          reference="resource"
        >
          <TextField source="EmployeeName" />
        </ReferenceField>

        <TextField label="Work Type" source="WorkTypeKey" />
        <DateField
          label="Transaction Date"
          source="TransDate"
          locales="en-GB"
        />
        <TextField label="Week #" source="WeekCode" />
        <NumberField source="HoursWorked" />
        <TextField source="DistRule" />
        <TextField source="DistRule2" />
        <TextField source="DistRule3" />

        <EditButton basePath="/timesheet" />
        <DeleteButton basePath="/timesheet" />
        <CloneButton />
      </Datagrid>
    </List>
  );
};

export default TimeSheetList;
