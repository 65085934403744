import React from 'react';
import {
    List, 
    ListActions,
    TextField,
    TextInput
  } from "react-admin";

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import { makeStyles } from '@material-ui/core';

const ModelForm = props => (
    <RowForm {...props}>     
        <TextInput label="Description"source="name" autoFocus />    
    </RowForm>
);

const ModelListActions = props => {
    const classes = useListActionsStyles();
    return <ListActions {...props} className={classes.root} />;
};

const useListActionsStyles = makeStyles(theme => ({
    root: {
        paddingBottom: 0,
        paddingTop: theme.spacing(4),
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: -theme.spacing(5),
    },
}));

const ModelList = props => {
    const classes = useStyles();
    return (
        <List
            {...props}
            actions={<ModelListActions />}
            className={classes.root}
            hasCreate
            empty={false}
            perPage={25}
        >
            <EditableDatagrid
                data-testid="model-datagrid"
                rowClick="edit"
                createForm={<ModelForm />}
                editForm={<ModelForm />}
            >
                <TextField label="Description" source="name" />
            </EditableDatagrid>
        </List>
    );
};

export default ModelList