import React from "react";
import { Layout } from "@react-admin/ra-enterprise";
import { AppLocationContext } from "@react-admin/ra-navigation";
import AppBar from "./AppBar";
import MyMenu from "./Menu";
//import MyMenu from "./Menu2";
import Breadcrumb from "./Breadcrumb";

const MyLayout = (props) => {
  return (
    <AppLocationContext>
      <Layout
        {...props}
        appBar={AppBar}
        menu={MyMenu}
        breadcrumb={Breadcrumb}
      />
    </AppLocationContext>
  );
};

export default MyLayout;
