import React, { FC, ReactNode, useState, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AVTimerIcon from '@material-ui/icons/AvTimer';
import BlockIcon from '@material-ui/icons/Block';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TourIcon from '@material-ui/icons/Flag';
import { makeStyles } from '@material-ui/core/styles';
import EventsIcon from '@material-ui/icons/FormatListNumbered';
import querystring from 'query-string';

import { MultiLevelMenu, MenuItem } from '@react-admin/ra-navigation';

import {
    Typography,
    Collapse,
    Tooltip,
    MenuItem as MUIMenuItem,
    ListItemIcon,
    List,
} from '@material-ui/core';

//timesheet
import TimerIcon from '@material-ui/icons/Timer'; //sap timesheet
import AlarmOnIcon from '@material-ui/icons/AlarmOn'; //erp timesheet

//miscelaneous
import PeopleIcon from '@material-ui/icons/People'; //resource
import AssessmentIcon from '@material-ui/icons/Assessment'; //project
import ViewModuleIcon from '@material-ui/icons/ViewModule'; //modules
import AssignmentIcon from '@material-ui/icons/Assignment'; //roles

//finishing schedule
import BuildIcon from '@material-ui/icons/Build'; //finishing schedule
import DomainIcon from '@material-ui/icons/Domain'; //Area
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode'; //model
import FlipToBackIcon from '@material-ui/icons/FlipToBack'; //range
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'; //types
import LocationOnIcon from '@material-ui/icons/LocationOn'; //location
import ViewColumnIcon from '@material-ui/icons/ViewColumn'; //finishes
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite'; //mood board

//report
import PrintIcon from '@material-ui/icons/Print';

import timesheet from '../timesheet'
import planning from '../planning'
import yachttemplate from '../yacht_template'

const MobileMenu = ({ logout, onMenuClick }) => {
    const classes = useStyles();
    const [menuState, setMenuState] = useState({
        timesheet: false,
        misc: false,
        yacht: false, 
        report: false, 
    });
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const handleToggle = (
        menu
    ) => {
        setMenuState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div className={classes.menu}>
            <MultiLevelMenu>
                <MenuItem
                    name="dashboard"
                    to="/"
                    exact
                    label="Dashboard"
                    icon={<DashboardIcon />}
                    onClick={onMenuClick}
                />
                <SubMenu
                    icon={<timesheet.icon fontSize="large"/>}
                    handleToggle={() => handleToggle('timesheet')}
                    isOpen={menuState.timesheet}
                    data-testid="commands-menu"
                    sidebarIsOpen={open}
                    label="Timesheets"
                >
                    <MenuItem
                        name="saptimesheet"
                        to="/timesheet"
                        icon={<TimerIcon/>}
                        onClick={onMenuClick}
                        label="SAP Timesheet"
                    />
                    <MenuItem
                        name="erstimesheet"
                        to="/erstimesheet"
                        icon={<AlarmOnIcon/>}
                        onClick={onMenuClick}
                        label="ERS Timesheet"
                    />
                </SubMenu>
                <SubMenu
                    icon={<planning.icon />}
                    handleToggle={() => handleToggle('misc')}
                    isOpen={menuState.misc}
                    sidebarIsOpen={open}
                    label="Miscelaneous"
                >
                    <MenuItem
                         name="resource"
                         to="/resource"
                         icon={<PeopleIcon />}
                         onClick={onMenuClick}
                         label="Resources"
                    />
                    <MenuItem
                         name="planning"
                         to="/planning"
                         icon={<AssessmentIcon />}
                         onClick={onMenuClick}
                         label="Projects"
                    />
                     <MenuItem
                        name="module"
                        to="/module"
                        icon={<ViewModuleIcon />}
                        onClick={onMenuClick}
                        label="Modules"
                    />
                     <MenuItem
                        name="employee"
                        to="/employee"
                        icon={<AssignmentIcon />}
                        onClick={onMenuClick}
                        label="Employee Roles"
                    />
                </SubMenu>
                <SubMenu
                    icon={<yachttemplate.icon />}
                    handleToggle={() => handleToggle('yacht')}
                    isOpen={menuState.yacht}
                    sidebarIsOpen={open}
                    label="Yacht Template"
                >
                        <MenuItem
                            name="finishingschd"
                            to={`/yachttemplate/hdr`}
                            onClick={onMenuClick}
                            label="Finishing Schedule"
                            icon={<BuildIcon/>}
                        />
                        <MenuItem
                            name="area"
                            to={`/area`}
                            onClick={onMenuClick}
                            label="Area"
                            icon={<DomainIcon/>}
                        />
                         <MenuItem
                            name="model"
                            to={`/model`}
                            onClick={onMenuClick}
                            label="Model"
                            icon={<DeveloperModeIcon/>}
                        />
                         <MenuItem
                            name="range"
                            to={`/range`}
                            onClick={onMenuClick}
                            label="Ranges"
                            icon={<FlipToBackIcon/>}
                        />
                        <MenuItem
                            name="types"
                            to={`/applicationarea`}
                            onClick={onMenuClick}
                            label="Types"
                            icon={<SettingsApplicationsIcon/>}
                        />
                        <MenuItem
                            name="locations"
                            to={`/ycttemplatelocation`}
                            onClick={onMenuClick}
                            label="Locations"
                            icon={<LocationOnIcon/>}
                        />
                        <MenuItem
                            name="item"
                            to={`/item`}
                            onClick={onMenuClick}
                            label="Finishes"
                            icon={<ViewColumnIcon/>}
                        />
                        <MenuItem
                            name="moodboard"
                            to={`/yachttemplate/moodboard`}
                            onClick={onMenuClick}
                            label="Mood Board"
                            icon={<SettingsInputCompositeIcon/>}
                        />                  
                </SubMenu>
                <SubMenu
                    icon={<AssessmentIcon/>}
                    handleToggle={() => handleToggle('report')}
                    isOpen={menuState.report}
                    sidebarIsOpen={open}
                    label="Reports"
                >
                   <MenuItem
                            name="printfinishingschedule"
                            to={`/printfinishingschedule`}
                            icon={<PrintIcon />}
                            onClick={onMenuClick}
                            label="Print fin schd (sec. sel with qty)"
                        />
                        <MenuItem
                            name="printfinishingschedulegroupbyfinish"
                            to={`/printfinishingschedulegroupbyfinish`}
                            icon={<PrintIcon />}
                            onClick={onMenuClick}
                            label="Print fin schd (summarized by finishes)"
                        />
                        <MenuItem
                            name="printfinishingschedulegroupbylocation"
                            to={'/printfinishingschedulegroupbylocation'}
                            icon={<PrintIcon />}
                            onClick={onMenuClick}
                            label="Print fin schd (summarized by locations)"
                        />
                </SubMenu>
                <span style={{ paddingLeft: 8 }}>{logout}</span>
            </MultiLevelMenu>
        </div>
    );
};

export default MobileMenu;

const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    icon,
    label,
    children,
    dense = false,
}) => {
    const classes = useStyles();

    const header = (
        <MUIMenuItem
            className={classes.subMenuItem}
            dense={dense}
            button
            onClick={handleToggle}
        >
            <ListItemIcon className={classes.icon}>
                {isOpen ? <ExpandMore /> : icon}
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {label}
            </Typography>
        </MUIMenuItem>
    );

    return (
        <>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={label} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    className={classes.subMenuContainer}
                    dense={dense}
                    component="div"
                    disablePadding
                >
                    {children}
                </List>
            </Collapse>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    menu: {
        minWidth: '50vw',
    },
    icon: {
        minWidth: theme.spacing(5),
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subMenuItem: {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    },
    subMenuContainer: {
        paddingLeft: theme.spacing(1.5),
    },
    noIconPadding: {
        paddingLeft: theme.spacing(1),
    },
}));
