import React from 'react';
import {
    Edit,
    TextInput,
    ImageInput,
    ImageField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    SelectInput,
    required,
  } from "react-admin";
import FinishPhoto from './FinishPhoto'
import { AccordionForm, AccordionSection, AccordionFormPanel } from '@react-admin/ra-form-layout';
import { MarkdownInput } from '@react-admin/ra-markdown';


const FinishTitle = ({ record }) =>
    record ? <span>Edit Finish #{record.id}</span> : null;

const FinishEdit = (props)=> {

    const requiredValidate = [required()];

return (
    <Edit
      title={<FinishTitle/>}  
      {...props}
    >
        
   
      <AccordionForm autoClose>
        <AccordionFormPanel label="Photo" >
            <FinishPhoto />
            {/* <TextInput disabled source="image_url" fullWidth />
            <TextInput disabled source="thumbnail_url" fullWidth />
            <TextInput disabled label="Image Title" source="image_title"/>    */}   

            <ImageInput source="pictures" label="Finishes pictures" accept="image/*" >
                <ImageField source="image_url" title="title" />
            </ImageInput>
            
        </AccordionFormPanel>
        
        <AccordionFormPanel label="General">
            <TextInput label="Code" source="id"  validate={requiredValidate}/>
            <TextInput label="Description" source="foreign_name"  validate={requiredValidate}/>
            <ReferenceInput 
                label="Item Group" 
                source="item_grp_code" 
                reference="itemgroup"                     
                sort={{ field: 'id', order: 'ASC' }}
                perPage={1000}  
                validate={requiredValidate}
                {...props}
                >
                <SelectInput />
            </ReferenceInput> 
            <ReferenceInput 
                label="Supplier" 
                source="supplier_code" 
                reference="supplier"                     
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}  
                validate={requiredValidate}
                {...props}
                >
                <AutocompleteInput />
            </ReferenceInput> 
            <ReferenceInput 
                label="Manufacturer" 
                source="manufacturer_code" 
                reference="manufacturer"                     
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}  
                validate={requiredValidate}
                {...props}
                >
                <AutocompleteInput />
            </ReferenceInput> 
            
           
        </AccordionFormPanel>

        <AccordionFormPanel label="Finishing Schedule">
            <SelectInput source="finish_type" initialValue="Standard" choices={[
                { id: 'Standard', name: 'Standard' },
                { id: 'Upgrade', name: 'Upgrade' }
            ]} />

            <ReferenceArrayInput 
                label="Types"
                source="itemProperties" 
                reference="applicationarea"
                sort={{ field: 'id', order: 'ASC' }}
                perPage={1000}  
                fullWidth           
            >
                <SelectArrayInput />
            </ReferenceArrayInput>

            <TextInput label="UOM" source="uom" />
            <TextInput label="Mfr Catalogue No." source="catalogue_num" />
            <TextInput label="Range" source="range" />
            <TextInput label="Colour" source="colour" />
            <TextInput label="Attribute 1" source="attr1" />
            <TextInput label="Attribute 2" source="attr2" />
            <TextInput label="Attribute 3" source="attr3" />

        </AccordionFormPanel>
     
        <AccordionFormPanel label="Description">
             <MarkdownInput source="details_of_finish" label="" />
        </AccordionFormPanel>  
    </AccordionForm>  

      
</Edit> 
    )   
}

export default FinishEdit;