import TitleIcon from '@material-ui/icons/Title';

import RangesList from './RangesList';
import RangesEdit from './RangesEdit';
import RangesCreate from './RangesCreate';

export default {
    list: RangesList,
    edit: RangesEdit,
    create: RangesCreate,
    icon: TitleIcon,
};