import TitleIcon from '@material-ui/icons/Title';

import ModuleList from './ModuleList';
import ModuleEdit from './ModuleEdit';
import ModuleCreate from './ModuleCreate';

export default {
    list: ModuleList,
    edit: ModuleEdit,
    create: ModuleCreate,
    icon: TitleIcon,
};