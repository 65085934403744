import TitleIcon from '@material-ui/icons/Title';

import FinishList from './FinishList';
import FinishEdit from './FinishEdit';
import FinishCreate from './FinishCreate';

export default {
    list: FinishList,
    edit: FinishEdit,
    //create: FinishCreate,
    icon: TitleIcon,
};