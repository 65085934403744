import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required
  } from "react-admin";

const CategoryCreate = (props)=> {

    const requiredValidate = [required()];

return (
    <Create
      title="Create Yacht Template Category"  
      {...props}
    >
      <SimpleForm redirect="list"
      >            
        <TextInput label="Code" source="id"  validate={requiredValidate}/>
        <TextInput label="Description" source="name"  validate={requiredValidate}/>
      </SimpleForm>  
</Create> 
)
}

export default CategoryCreate;