import React from 'react';
import {
    List, 
    Datagrid, 
    TextField,
  } from "react-admin";
import { makeStyles } from '@material-ui/core';

//import AreasField from './AreasField'  

const useAsideStyles = makeStyles({
    root: {
        marginLeft: '1em',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        width: 150,
    },
});

const Aside = () => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            Click on a module to see an Editable datagrid of subjects
        </div>
    );
};

const useModuleListStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
});

const ModuleList = props => {
    const classes = useModuleListStyles(props);
return(
    <List  
        title="Menu List"
        aside={<Aside />}
        //actions={false}
        hasCreate
        className={classes.root}
        {...props}
    >
        <Datagrid rowClick="edit">
            <TextField label="Description" source="name" />   
            {/* <AreasField label="Areas"/>                   */}
        </Datagrid>
    </List>
);
};

export default ModuleList;