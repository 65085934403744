import React from 'react';
import {
    List, 
    ListActions,
    TextField,
    NumberField,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    ReferenceArrayInput,
    SelectArrayInput
  } from "react-admin";

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import { makeStyles } from '@material-ui/core';
import AreaModelsField from './AreaModelsField'  

const AreaForm = props => (
    <RowForm {...props}>     
        <TextInput label="Description"source="name" autoFocus />   

        <ReferenceInput
          label="Type" 
          source="item_property"
          reference="applicationarea"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
        >
          <SelectInput />
        </ReferenceInput>

        <ReferenceArrayInput 
              label="Applicable Models"
              source="selectedModels" 
              reference="model"
              sort={{ field: 'name', order: 'ASC' }}         
              perPage={1000}                        
              fullWidth             
              >             
              <SelectArrayInput/>
          </ReferenceArrayInput>

        <ReferenceInput
              label="Location" 
              source="location"
              reference="ycttemplatelocation"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={1000}
            >
          <SelectInput />
        </ReferenceInput>

        <NumberInput label="Quantity" source="quantity" />
        <NumberInput label="Price" source="price" />
        <FormDataConsumer>
          {({  formData, ...rest }) => {
            if (typeof formData !== "undefined") {
              formData.total = formData.quantity * formData.price;
              return (
                <NumberInput
                  disabled
                  defaultValue={formData.total}
                  label="Total"
                  source="total"
                />
              );
            } else {
              return (
                <NumberInput
                  disabled
                  label="Total"
                  source="total"
                />
              );
            }
          }}
        </FormDataConsumer>
    </RowForm>
);

const AreaListActions = props => {
    const classes = useListActionsStyles();
    return <ListActions {...props} className={classes.root} />;
};

const useListActionsStyles = makeStyles(theme => ({
    root: {
        paddingBottom: 0,
        paddingTop: theme.spacing(2),
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: -theme.spacing(5),
    },
}));

const AreaList = props => {
    const classes = useStyles();
    return (
        <List
            {...props}
            actions={<AreaListActions />}
            className={classes.root}
            hasCreate
            empty={false}
            perPage={25}
        >
            <EditableDatagrid
                data-testid="area-datagrid"
                rowClick="edit"
                createForm={<AreaForm />}
                editForm={<AreaForm />}
            >
                <TextField label="Description" source="name" />
                <TextField label="Type" source="type.name" />
                <AreaModelsField label="Applicable Models"/>   
                <TextField label="Location" source="locationObj.name" />
                <NumberField label="Quantity" source="quantity" />
                <NumberField label="Price" source="price" />
                <NumberField label="Total" source="total" />
            </EditableDatagrid>
        </List>
    );
};

export default AreaList